.checkbox {
  $el: &;

  display: flex;
  flex-flow: column nowrap;
  position: relative;

  // &:focus-within {
  //   #{$el}__label {
  //     &::before {
  //       border-color: $color-input;
  //     }
  //   }
  // }

  &-checked + #{$el}__label {
    &::before {
      background-color: $color-input-accent;
      border-color: $color-input-accent;
    }

    &::after {
      opacity: 1;
    }
  }

  &__input {
    bottom: 0;
    height: rem($checkbox-height);
    left: 0;
    margin: 0;
    min-width: rem($checkbox-height);
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: $index-form;

    &[disabled] {
      & + #{$el}__label {
        color: $color-disabled;
        pointer-events: none;

        &::before {
          background-color: $color-disabled;
          border: 0;
        }
      }
    }

    &:checked + #{$el}__label {
      &::before {
        background-color: $color-input-accent;
        border-color: $color-input-accent;
      }

      &::after {
        opacity: 1;
      }
    }

    &:active + #{$el}__label {
      &::before {
        border-color: darken($color-input-accent, 10%);
      }
    }

    &-error + #{$el}__label {
      &::before {
        border-color: $color-error;
        box-shadow: 0px 0px 0px 1px $color-error;
      }
    }
  }

  &__label {
    color: $color-text;
    display: inline-block;
    font: $font-regular #{rem(15)} / #{rem(22)} $font-text;
    letter-spacing: -0.5px;
    margin-top: rem(2);
    padding: rem(2) 0 0 rem($checkbox-height + 9);
    pointer-events: none;
    position: relative;
    text-align: left;
    z-index: $index-form + 1;

    &::before {
      background-color: $color-bg;
      border: $border-input solid $color-input;
      border-radius: 4px;
      content: '';
      display: block;
      height: rem($checkbox-height);
      left: 0;
      pointer-events: none;
      position: absolute;
      transition: background-color $transition-hover ease-in-out,  border-color $transition-hover ease-in-out;
      top: rem(-1);
      width: rem($checkbox-height);
      z-index: 0;
    }

    &::after {
      border: 3px solid $color-white;
      border-radius: 1px;
      border-right: 0;
      border-top: 0;
      content: '';
      height: rem(10);
      left: rem(5);
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: rem(5);
      transform: rotate(-45deg);
      transition: opacity $transition-hover ease-in-out;
      width: rem(16);
    }
  }

  &__link {
    color: $color-primary;
    pointer-events: all;
    text-align: left;
    text-decoration: underline;    
  }
}
