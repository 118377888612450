$poppins-path: './../../../assets/fonts/poppins' !default;
$work-sans-path: '/../../../assets/fonts/work-sans' !default;

@font-face {
  font-family: 'Work-Sans';
  src: url('#{$work-sans-path}.eot') format('eot');
  src: url('#{$work-sans-path}.eot?#iefix') format('embedded-opentype'),
  url('#{$work-sans-path}.woff2') format('woff2'),
  url('#{$work-sans-path}.woff') format('woff'),
  url('#{$work-sans-path}.ttf') format('truetype'),
  url('#{$work-sans-path}.svg#webfont') format('svg');
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('#{$poppins-path}-medium.eot') format('eot');
  src: url('#{$poppins-path}-medium.eot?#iefix') format('embedded-opentype'),
  url('#{$poppins-path}-medium.woff2') format('woff2'),
  url('#{$poppins-path}-medium.woff') format('woff'),
  url('#{$poppins-path}-medium.ttf') format('truetype'),
  url('#{$poppins-path}-medium.svg#webfont') format('svg');
  font-style: normal;
  font-weight: $font-medium;
}

@font-face {
  font-family: 'Poppins';
  src: url('#{$poppins-path}-semibold.eot') format('eot');
  src: url('#{$poppins-path}-semibold.eot?#iefix') format('embedded-opentype'),
  url('#{$poppins-path}-semibold.woff2') format('woff2'),
  url('#{$poppins-path}-semibold.woff') format('woff'),
  url('#{$poppins-path}-semibold.ttf') format('truetype'),
  url('#{$poppins-path}-semibold.svg#webfont') format('svg');
  font-style: normal;
  font-weight: $font-semibold;
}
