* {
  margin: 0;
  padding: 0;
}
*, *::before, *::after {
  box-sizing: border-box;
}

ol,
ul {
  list-style-type: none;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

span {
  display: inline-block;
}

a,
button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background: none;
  border: 0;
}

/* Use:
  .element {
    font-size: rem(20);
  }
*/
@font-face {
  font-family: "Work-Sans";
  src: url("/../../../assets/fonts/work-sans.eot") format("eot");
  src: url("/../../../assets/fonts/work-sans.eot?#iefix") format("embedded-opentype"), url("/../../../assets/fonts/work-sans.woff2") format("woff2"), url("/../../../assets/fonts/work-sans.woff") format("woff"), url("/../../../assets/fonts/work-sans.ttf") format("truetype"), url("/../../../assets/fonts/work-sans.svg#webfont") format("svg");
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./../../../assets/fonts/poppins-medium.eot") format("eot");
  src: url("./../../../assets/fonts/poppins-medium.eot?#iefix") format("embedded-opentype"), url("./../../../assets/fonts/poppins-medium.woff2") format("woff2"), url("./../../../assets/fonts/poppins-medium.woff") format("woff"), url("./../../../assets/fonts/poppins-medium.ttf") format("truetype"), url("./../../../assets/fonts/poppins-medium.svg#webfont") format("svg");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Poppins";
  src: url("./../../../assets/fonts/poppins-semibold.eot") format("eot");
  src: url("./../../../assets/fonts/poppins-semibold.eot?#iefix") format("embedded-opentype"), url("./../../../assets/fonts/poppins-semibold.woff2") format("woff2"), url("./../../../assets/fonts/poppins-semibold.woff") format("woff"), url("./../../../assets/fonts/poppins-semibold.ttf") format("truetype"), url("./../../../assets/fonts/poppins-semibold.svg#webfont") format("svg");
  font-style: normal;
  font-weight: 600;
}
.mt-xs {
  margin-top: 4px;
}

.mb-xs {
  margin-bottom: 4px;
}

.mt-s {
  margin-top: 8px;
}

.mb-s {
  margin-bottom: 8px;
}

.mt-m {
  margin-top: 16px;
}

.mb-m {
  margin-bottom: 16px;
}

.mt-l {
  margin-top: 28px;
}

.mb-l {
  margin-bottom: 28px;
}

.mt-xl {
  margin-top: 32px;
}

.mb-xl {
  margin-bottom: 32px;
}

.mt-xxl {
  margin-top: 64px;
}

.mb-xxl {
  margin-bottom: 64px;
}

.mt-xxxl {
  margin-top: 96px;
}

.mb-xxxl {
  margin-bottom: 96px;
}

.mr-xs {
  margin-right: 2px;
}

.ml-xs {
  margin-left: 2px;
}

.mr-s {
  margin-right: 4px;
}

.ml-s {
  margin-left: 4px;
}

.mr-m {
  margin-right: 8px;
}

.ml-m {
  margin-left: 8px;
}

.mr-l {
  margin-right: 16px;
}

.ml-l {
  margin-left: 16px;
}

.mr-xl {
  margin-right: 32px;
}

.ml-xl {
  margin-left: 32px;
}

.mr-xxl {
  margin-right: 64px;
}

.ml-xxl {
  margin-left: 64px;
}

html {
  overflow-x: hidden;
}
html.locked {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
}

body {
  --ion-background-color: #fff;
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  width: 100%;
}
@media (min-width: 375px) {
  .wrapper {
    padding: 0 30px;
  }
}
@media (min-width: 414px) {
  .wrapper {
    padding: 0 40px;
  }
}

:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 173, 0, 0);
}

@font-face {
  font-family: "icons";
  src: url("./../../../assets/fonts//icons.woff?hru9ow") format("woff"), url("./../../../assets/fonts//icons.ttf?hru9ow") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  font-family: "icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1rem;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home-1:before {
  content: "\e900";
}

.icon-home-2:before {
  content: "\e902";
}

.icon-office:before {
  content: "\e903";
}

.icon-newspaper:before {
  content: "\e904";
}

.icon-pencil:before {
  content: "\eb1a";
}

.icon-quill:before {
  content: "\e907";
}

.icon-pen-1:before {
  content: "\e908";
}

.icon-pen-2:before {
  content: "\e91d";
}

.icon-eyedropper:before {
  content: "\e90a";
}

.icon-droplet:before {
  content: "\e90b";
}

.icon-paint-format:before {
  content: "\e90c";
}

.icon-image:before {
  content: "\e90d";
}

.icon-gallery:before {
  content: "\e90e";
}

.icon-camera:before {
  content: "\e90f";
}

.icon-headphones:before {
  content: "\e910";
}

.icon-music:before {
  content: "\e911";
}

.icon-bullhorn:before {
  content: "\e91a";
}

.icon-connection:before {
  content: "\e91b";
}

.icon-mic:before {
  content: "\e91e";
}

.icon-book-1:before {
  content: "\e91f";
}

.icon-book-2:before {
  content: "\eb0d";
}

.icon-books:before {
  content: "\e920";
}

.icon-library:before {
  content: "\e921";
}

.icon-file-empty:before {
  content: "\e924";
}

.icon-files-empty:before {
  content: "\e925";
}

.icon-file-text:before {
  content: "\e926";
}

.icon-file-picture:before {
  content: "\e927";
}

.icon-file-music:before {
  content: "\e928";
}

.icon-file-play:before {
  content: "\e929";
}

.icon-file-video:before {
  content: "\e92a";
}

.icon-file-zip:before {
  content: "\e92b";
}

.icon-copy:before {
  content: "\e92c";
}

.icon-paste:before {
  content: "\e92d";
}

.icon-stack:before {
  content: "\e92e";
}

.icon-folder:before {
  content: "\e92f";
}

.icon-folder-open:before {
  content: "\e930";
}

.icon-folder-plus:before {
  content: "\e931";
}

.icon-folder-minus:before {
  content: "\e932";
}

.icon-folder-download:before {
  content: "\e933";
}

.icon-folder-upload:before {
  content: "\e934";
}

.icon-price-tag:before {
  content: "\e935";
}

.icon-price-tags:before {
  content: "\e936";
}

.icon-barcode:before {
  content: "\e937";
}

.icon-qrcode:before {
  content: "\e938";
}

.icon-ticket:before {
  content: "\e939";
}

.icon-cart:before {
  content: "\e93a";
}

.icon-coin-dollar:before {
  content: "\e93b";
}

.icon-coin-euro:before {
  content: "\e93c";
}

.icon-coin-pound:before {
  content: "\e93d";
}

.icon-coin-yen:before {
  content: "\e93e";
}

.icon-credit-card:before {
  content: "\e93f";
}

.icon-calculator:before {
  content: "\e940";
}

.icon-lifebuoy:before {
  content: "\e941";
}

.icon-phone:before {
  content: "\e942";
}

.icon-phone-hang-up:before {
  content: "\e943";
}

.icon-address-book:before {
  content: "\e944";
}

.icon-envelop:before {
  content: "\e945";
}

.icon-pushpin:before {
  content: "\e946";
}

.icon-location-1:before {
  content: "\e947";
}

.icon-location-2:before {
  content: "\e948";
}

.icon-compass:before {
  content: "\e949";
}

.icon-compass2:before {
  content: "\e94a";
}

.icon-map-1:before {
  content: "\e94b";
}

.icon-map-2:before {
  content: "\e94c";
}

.icon-history:before {
  content: "\e94d";
}

.icon-clock-1:before {
  content: "\e94e";
}

.icon-clock-2:before {
  content: "\e94f";
}

.icon-alarm:before {
  content: "\e950";
}

.icon-bell:before {
  content: "\e951";
}

.icon-stop-watch:before {
  content: "\e952";
}

.icon-calendar-1:before {
  content: "\e953";
}

.icon-calendar-2:before {
  content: "\eafe";
}

.icon-calendar-3:before {
  content: "\eb24";
}

.icon-printer:before {
  content: "\e954";
}

.icon-key-board:before {
  content: "\e955";
}

.icon-display:before {
  content: "\e956";
}

.icon-laptop-1:before {
  content: "\e957";
}

.icon-laptop-2:before {
  content: "\eb19";
}

.icon-mobile-1:before {
  content: "\e958";
}

.icon-mobile-2:before {
  content: "\e959";
}

.icon-tablet:before {
  content: "\e95a";
}

.icon-tv:before {
  content: "\e95b";
}

.icon-drawer-1:before {
  content: "\e95c";
}

.icon-drawer-2:before {
  content: "\e95d";
}

.icon-box-add:before {
  content: "\e95e";
}

.icon-box-remove:before {
  content: "\e95f";
}

.icon-download-1:before {
  content: "\e960";
}

.icon-download-2:before {
  content: "\e9c5";
}

.icon-download-3:before {
  content: "\e9c7";
}

.icon-download-4:before {
  content: "\eb22";
}

.icon-upload-1:before {
  content: "\e961";
}

.icon-upload-2:before {
  content: "\e9c6";
}

.icon-upload-3:before {
  content: "\e9c8";
}

.icon-floppy-disk:before {
  content: "\e962";
}

.icon-drive:before {
  content: "\e963";
}

.icon-database:before {
  content: "\e964";
}

.icon-undo-1:before {
  content: "\e965";
}

.icon-undo-2:before {
  content: "\e967";
}

.icon-redo-1:before {
  content: "\e966";
}

.icon-redo-2:before {
  content: "\e968";
}

.icon-reply:before {
  content: "\e96a";
}

.icon-bubble-1:before {
  content: "\e96b";
}

.icon-bubble-2:before {
  content: "\e96e";
}

.icon-bubbles-1:before {
  content: "\e96c";
}

.icon-bubbles-2:before {
  content: "\e96d";
}

.icon-bubbles-3:before {
  content: "\e96f";
}

.icon-bubbles-4:before {
  content: "\e970";
}

.icon-user:before {
  content: "\e971";
}

.icon-users:before {
  content: "\e972";
}

.icon-user-plus:before {
  content: "\e973";
}

.icon-user-minus:before {
  content: "\e974";
}

.icon-user-check:before {
  content: "\e975";
}

.icon-user-tie:before {
  content: "\e976";
}

.icon-quotes-left:before {
  content: "\e977";
}

.icon-quotes-right:before {
  content: "\e978";
}

.icon-hour-glass:before {
  content: "\e979";
}

.icon-spinner-1:before {
  content: "\e97a";
}

.icon-spinner-2:before {
  content: "\e97b";
}

.icon-spinner-3:before {
  content: "\e97c";
}

.icon-spinner-4:before {
  content: "\e97d";
}

.icon-spinner-5:before {
  content: "\e97e";
}

.icon-spinner-6:before {
  content: "\e97f";
}

.icon-spinner-7:before {
  content: "\e980";
}

.icon-spinner-8:before {
  content: "\e981";
}

.icon-spinner-9:before {
  content: "\e982";
}

.icon-spinner-10:before {
  content: "\e983";
}

.icon-spinner-11:before {
  content: "\e984";
}

.icon-spinner-12:before {
  content: "\e917";
}

.icon-binoculars:before {
  content: "\e985";
}

.icon-search-1:before {
  content: "\e986";
}

.icon-search-2:before {
  content: "\eafb";
}

.icon-zoom-in:before {
  content: "\e987";
}

.icon-zoom-out:before {
  content: "\e988";
}

.icon-enlarge:before {
  content: "\e989";
}

.icon-shrink:before {
  content: "\e98a";
}

.icon-enlarge2:before {
  content: "\e98b";
}

.icon-shrink2:before {
  content: "\e98c";
}

.icon-key-1:before {
  content: "\e98d";
}

.icon-key-2:before {
  content: "\e98e";
}

.icon-lock:before {
  content: "\e98f";
}

.icon-unlocked:before {
  content: "\e990";
}

.icon-wrench:before {
  content: "\e991";
}

.icon-equalizer-1:before {
  content: "\e992";
}

.icon-equalizer-2:before {
  content: "\e993";
}

.icon-cog:before {
  content: "\e994";
}

.icon-cogs:before {
  content: "\e995";
}

.icon-hammer-1:before {
  content: "\e996";
}

.icon-hammer-2:before {
  content: "\e9a8";
}

.icon-magic-wand:before {
  content: "\e997";
}

.icon-aid-kit:before {
  content: "\e998";
}

.icon-bug:before {
  content: "\e999";
}

.icon-pie-chart:before {
  content: "\e99a";
}

.icon-stats-dots:before {
  content: "\e99b";
}

.icon-stats-bars-1:before {
  content: "\e99c";
}

.icon-stats-bars-2:before {
  content: "\e99d";
}

.icon-trophy:before {
  content: "\e99e";
}

.icon-gift:before {
  content: "\e99f";
}

.icon-glass-1:before {
  content: "\e9a0";
}

.icon-glass-2:before {
  content: "\e9a1";
}

.icon-mug:before {
  content: "\e9a2";
}

.icon-spoon-knife:before {
  content: "\e9a3";
}

.icon-leaf:before {
  content: "\e9a4";
}

.icon-rocket-1:before {
  content: "\e9a5";
}

.icon-rocket-2:before {
  content: "\eb0f";
}

.icon-meter-1:before {
  content: "\e9a6";
}

.icon-meter-2:before {
  content: "\e9a7";
}

.icon-fire:before {
  content: "\e9a9";
}

.icon-lab:before {
  content: "\e9aa";
}

.icon-magnet:before {
  content: "\e9ab";
}

.icon-bin-1:before {
  content: "\e9ac";
}

.icon-bin-2:before {
  content: "\e9ad";
}

.icon-briefcase:before {
  content: "\e9ae";
}

.icon-airplane:before {
  content: "\e9af";
}

.icon-truck:before {
  content: "\e9b0";
}

.icon-road:before {
  content: "\e9b1";
}

.icon-accessibility:before {
  content: "\e9b2";
}

.icon-target:before {
  content: "\e9b3";
}

.icon-shield:before {
  content: "\e9b4";
}

.icon-power:before {
  content: "\e9b5";
}

.icon-switch:before {
  content: "\e9b6";
}

.icon-power-cord:before {
  content: "\e9b7";
}

.icon-clipboard:before {
  content: "\e9b8";
}

.icon-list-numbered:before {
  content: "\e9b9";
}

.icon-list-1:before {
  content: "\e9ba";
}

.icon-list-2:before {
  content: "\e9bb";
}

.icon-tree:before {
  content: "\e9bc";
}

.icon-menu-1:before {
  content: "\e9bd";
}

.icon-menu-2:before {
  content: "\e9be";
}

.icon-menu-3:before {
  content: "\e9bf";
}

.icon-menu-4:before {
  content: "\e9c0";
}

.icon-menu-5:before {
  content: "\eafa";
}

.icon-cloud:before {
  content: "\e9c1";
}

.icon-cloud-download:before {
  content: "\e9c2";
}

.icon-cloud-upload:before {
  content: "\e9c3";
}

.icon-cloud-check:before {
  content: "\e9c4";
}

.icon-sphere:before {
  content: "\e9c9";
}

.icon-earth:before {
  content: "\e9ca";
}

.icon-link:before {
  content: "\e9cb";
}

.icon-flag:before {
  content: "\e9cc";
}

.icon-attachment:before {
  content: "\e9cd";
}

.icon-eye:before {
  content: "\e9ce";
}

.icon-eye-plus:before {
  content: "\e9cf";
}

.icon-eye-minus:before {
  content: "\e9d0";
}

.icon-eye-blocked:before {
  content: "\e9d1";
}

.icon-bookmark:before {
  content: "\e9d2";
}

.icon-bookmarks:before {
  content: "\e9d3";
}

.icon-sun:before {
  content: "\e9d4";
}

.icon-contrast:before {
  content: "\e9d5";
}

.icon-brightness-contrast:before {
  content: "\e9d6";
}

.icon-star-empty:before {
  content: "\e9d7";
}

.icon-star-half:before {
  content: "\e9d8";
}

.icon-star-full:before {
  content: "\e9d9";
}

.icon-heart:before {
  content: "\e9da";
}

.icon-heart-broken:before {
  content: "\e9db";
}

.icon-man:before {
  content: "\e9dc";
}

.icon-woman:before {
  content: "\e9dd";
}

.icon-man-woman:before {
  content: "\e9de";
}

.icon-point-up:before {
  content: "\ea03";
}

.icon-point-right:before {
  content: "\ea04";
}

.icon-point-down:before {
  content: "\ea05";
}

.icon-point-left:before {
  content: "\ea06";
}

.icon-warning:before {
  content: "\ea07";
}

.icon-notification:before {
  content: "\ea08";
}

.icon-question:before {
  content: "\ea09";
}

.icon-plus-1:before {
  content: "\ea0a";
}

.icon-plus-2:before {
  content: "\eafc";
}

.icon-plus-3:before {
  content: "\e901";
}

.icon-minus:before {
  content: "\ea0b";
}

.icon-info:before {
  content: "\ea0c";
}

.icon-cancel-circle:before {
  content: "\ea0d";
}

.icon-blocked:before {
  content: "\ea0e";
}

.icon-cross-1:before {
  content: "\ea0f";
}

.icon-cross-2:before {
  content: "\e916";
}

.icon-checkmark-1:before {
  content: "\ea10";
}

.icon-checkmark-2:before {
  content: "\ea11";
}

.icon-spell-check:before {
  content: "\ea12";
}

.icon-enter:before {
  content: "\ea13";
}

.icon-exit:before {
  content: "\ea14";
}

.icon-play-1:before {
  content: "\e912";
}

.icon-play-2:before {
  content: "\ea15";
}

.icon-play-3:before {
  content: "\ea1c";
}

.icon-pause-1:before {
  content: "\ea16";
}

.icon-pause-2:before {
  content: "\ea1d";
}

.icon-stop-1:before {
  content: "\ea17";
}

.icon-previous-1:before {
  content: "\ea18";
}

.icon-previous-2:before {
  content: "\ea23";
}

.icon-next-1:before {
  content: "\ea19";
}

.icon-next-2:before {
  content: "\ea24";
}

.icon-backward-1:before {
  content: "\ea1a";
}

.icon-backward-2:before {
  content: "\ea1f";
}

.icon-forward-1:before {
  content: "\e969";
}

.icon-forward-2:before {
  content: "\ea1b";
}

.icon-forward-3:before {
  content: "\ea20";
}

.icon-stop-2:before {
  content: "\ea1e";
}

.icon-first:before {
  content: "\ea21";
}

.icon-last:before {
  content: "\ea22";
}

.icon-eject:before {
  content: "\ea25";
}

.icon-volume-high:before {
  content: "\ea26";
}

.icon-volume-medium:before {
  content: "\ea27";
}

.icon-volume-low:before {
  content: "\ea28";
}

.icon-volume-mute-1:before {
  content: "\ea29";
}

.icon-volume-mute-2:before {
  content: "\ea2a";
}

.icon-volume-increase:before {
  content: "\ea2b";
}

.icon-volume-decrease:before {
  content: "\ea2c";
}

.icon-loop-1:before {
  content: "\ea2d";
}

.icon-loop-2:before {
  content: "\ea2e";
}

.icon-infinite:before {
  content: "\ea2f";
}

.icon-shuffle:before {
  content: "\ea30";
}

.icon-arrow-up-left-1:before {
  content: "\ea31";
}

.icon-arrow-up-left-2:before {
  content: "\ea39";
}

.icon-arrow-up-1:before {
  content: "\ea32";
}

.icon-arrow-up-2:before {
  content: "\ea3a";
}

.icon-arrow-up-3:before {
  content: "\eaef";
}

.icon-arrow-up-4:before {
  content: "\e909";
}

.icon-arrow-up-right-1:before {
  content: "\ea33";
}

.icon-arrow-up-right-2:before {
  content: "\ea3b";
}

.icon-arrow-right-1:before {
  content: "\ea34";
}

.icon-arrow-right-2:before {
  content: "\ea3c";
}

.icon-arrow-right-3:before {
  content: "\eaf0";
}

.icon-arrow-right-4:before {
  content: "\e913";
}

.icon-arrow-down-right-1:before {
  content: "\ea35";
}

.icon-arrow-down-right-2:before {
  content: "\ea3d";
}

.icon-arrow-down-1:before {
  content: "\ea36";
}

.icon-arrow-down-2:before {
  content: "\ea3e";
}

.icon-arrow-down-3:before {
  content: "\eaf1";
}

.icon-arrow-down-4:before {
  content: "\e914";
}

.icon-arrow-down-left-1:before {
  content: "\ea37";
}

.icon-arrow-down-left-2:before {
  content: "\ea3f";
}

.icon-arrow-left-1:before {
  content: "\ea38";
}

.icon-arrow-left-2:before {
  content: "\ea40";
}

.icon-arrow-left-3:before {
  content: "\eaf2";
}

.icon-arrow-left-4:before {
  content: "\e915";
}

.icon-circle-up:before {
  content: "\ea41";
}

.icon-circle-right:before {
  content: "\ea42";
}

.icon-circle-down:before {
  content: "\ea43";
}

.icon-circle-left:before {
  content: "\ea44";
}

.icon-tab:before {
  content: "\ea45";
}

.icon-move-up:before {
  content: "\ea46";
}

.icon-move-down:before {
  content: "\ea47";
}

.icon-sort-alpha-asc:before {
  content: "\ea48";
}

.icon-sort-alpha-desc:before {
  content: "\ea49";
}

.icon-sort-numeric-asc:before {
  content: "\ea4a";
}

.icon-sort-numberic-desc:before {
  content: "\ea4b";
}

.icon-sort-amount-asc:before {
  content: "\ea4c";
}

.icon-sort-amount-desc:before {
  content: "\ea4d";
}

.icon-command:before {
  content: "\ea4e";
}

.icon-shift:before {
  content: "\ea4f";
}

.icon-ctrl:before {
  content: "\ea50";
}

.icon-opt:before {
  content: "\ea51";
}

.icon-checkbox-checked:before {
  content: "\ea52";
}

.icon-checkbox-unchecked:before {
  content: "\ea53";
}

.icon-radio-checked-1:before {
  content: "\ea54";
}

.icon-radio-checked-2:before {
  content: "\ea55";
}

.icon-radio-unchecked:before {
  content: "\ea56";
}

.icon-crop:before {
  content: "\ea57";
}

.icon-make-group:before {
  content: "\ea58";
}

.icon-ungroup:before {
  content: "\ea59";
}

.icon-scissors:before {
  content: "\ea5a";
}

.icon-filter-1:before {
  content: "\ea5b";
}

.icon-filter-2:before {
  content: "\eb02";
}

.icon-font:before {
  content: "\ea5c";
}

.icon-ligature-1:before {
  content: "\ea5d";
}

.icon-ligature-2:before {
  content: "\ea5e";
}

.icon-text-height:before {
  content: "\ea5f";
}

.icon-text-width:before {
  content: "\ea60";
}

.icon-font-size:before {
  content: "\ea61";
}

.icon-bold:before {
  content: "\ea62";
}

.icon-underline:before {
  content: "\ea63";
}

.icon-italic:before {
  content: "\ea64";
}

.icon-strikethrough:before {
  content: "\ea65";
}

.icon-omega:before {
  content: "\ea66";
}

.icon-sigma:before {
  content: "\ea67";
}

.icon-page-break:before {
  content: "\ea68";
}

.icon-superscript-1:before {
  content: "\ea69";
}

.icon-superscript-2:before {
  content: "\ea6b";
}

.icon-subscript-1:before {
  content: "\ea6a";
}

.icon-subscript-2:before {
  content: "\ea6c";
}

.icon-text-color:before {
  content: "\ea6d";
}

.icon-pagebreak:before {
  content: "\ea6e";
}

.icon-clear-formatting:before {
  content: "\ea6f";
}

.icon-table-1:before {
  content: "\ea70";
}

.icon-table-2:before {
  content: "\ea71";
}

.icon-insert-template:before {
  content: "\ea72";
}

.icon-pilcrow:before {
  content: "\ea73";
}

.icon-ltr:before {
  content: "\ea74";
}

.icon-rtl:before {
  content: "\ea75";
}

.icon-section:before {
  content: "\ea76";
}

.icon-paragraph-left:before {
  content: "\ea77";
}

.icon-paragraph-center:before {
  content: "\ea78";
}

.icon-paragraph-right:before {
  content: "\ea79";
}

.icon-paragraph-justify:before {
  content: "\ea7a";
}

.icon-indent-increase:before {
  content: "\ea7b";
}

.icon-indent-decrease:before {
  content: "\ea7c";
}

.icon-share-1:before {
  content: "\ea7d";
}

.icon-share-2:before {
  content: "\ea82";
}

.icon-new-tab:before {
  content: "\ea7e";
}

.icon-embed-1:before {
  content: "\ea7f";
}

.icon-embed-2:before {
  content: "\ea80";
}

.icon-terminal:before {
  content: "\ea81";
}

.icon-facebook-1:before {
  content: "\ea91";
}

.icon-facebook-2:before {
  content: "\eb11";
}

.icon-instagram-1:before {
  content: "\ea92";
}

.icon-instagram-2:before {
  content: "\eb16";
}

.icon-whatsapp-1:before {
  content: "\ea93";
}

.icon-whatsapp-2:before {
  content: "\eb1e";
}

.icon-twitter-1:before {
  content: "\ea96";
}

.icon-twitter-2:before {
  content: "\eb14";
}

.icon-rss-1:before {
  content: "\ea9b";
}

.icon-rss-2:before {
  content: "\ea9c";
}

.icon-youtube-1:before {
  content: "\ea9d";
}

.icon-youtube-2:before {
  content: "\ea9e";
}

.icon-youtube-3:before {
  content: "\eb15";
}

.icon-dropbox:before {
  content: "\eaae";
}

.icon-onedrive:before {
  content: "\eaaf";
}

.icon-appleinc:before {
  content: "\eabe";
}

.icon-android:before {
  content: "\eac0";
}

.icon-skype:before {
  content: "\eac5";
}

.icon-reddit:before {
  content: "\eac6";
}

.icon-linkedin-1:before {
  content: "\eac9";
}

.icon-linkedin-2:before {
  content: "\eb12";
}

.icon-paypal:before {
  content: "\ead8";
}

.icon-chrome:before {
  content: "\ead9";
}

.icon-firefox:before {
  content: "\eada";
}

.icon-safari:before {
  content: "\eadd";
}

.icon-opera:before {
  content: "\eade";
}

.icon-file-pdf:before {
  content: "\eadf";
}

.icon-file-openoffice:before {
  content: "\eae0";
}

.icon-file-word:before {
  content: "\eae1";
}

.icon-file-excel:before {
  content: "\eae2";
}

.icon-libreoffice:before {
  content: "\eae3";
}

.icon-git:before {
  content: "\eae7";
}

.icon-codepen:before {
  content: "\eae8";
}

.icon-svg:before {
  content: "\eae9";
}

.icon-icomoon:before {
  content: "\eaea";
}

.icon-angle-top:before {
  content: "\eaeb";
}

.icon-angle-right:before {
  content: "\eaec";
}

.icon-angle-down:before {
  content: "\eaed";
}

.icon-angle-left:before {
  content: "\eaee";
}

.icon-triangle-top:before {
  content: "\eaf3";
}

.icon-triangle-right:before {
  content: "\eaf4";
}

.icon-triangle-down:before {
  content: "\eaf5";
}

.icon-triangle-left:before {
  content: "\eaf6";
}

.icon-checkbox:before {
  content: "\eaf7";
}

.icon-checked:before {
  content: "\eaf8";
}

.icon-check:before {
  content: "\eaf9";
}

.icon-less:before {
  content: "\eafd";
}

.icon-today:before {
  content: "\eaff";
}

.icon-clock-3:before {
  content: "\eb00";
}

.icon-clock-4:before {
  content: "\e918";
}

.icon-timelapse:before {
  content: "\eb01";
}

.icon-visibility:before {
  content: "\eb03";
}

.icon-location-3:before {
  content: "\eb04";
}

.icon-mail:before {
  content: "\eb05";
}

.icon-call:before {
  content: "\eb06";
}

.icon-person:before {
  content: "\eb07";
}

.icon-mask:before {
  content: "\eb08";
}

.icon-chat:before {
  content: "\eb09";
}

.icon-language:before {
  content: "\eb0a";
}

.icon-computer:before {
  content: "\eb0b";
}

.icon-verified:before {
  content: "\eb0c";
}

.icon-brain:before {
  content: "\eb0e";
}

.icon-star:before {
  content: "\eb10";
}

.icon-telegram:before {
  content: "\eb13";
}

.icon-temple:before {
  content: "\eb17";
}

.icon-tool:before {
  content: "\eb18";
}

.icon-pencil:before {
  content: "\eb1a";
}

.icon-record-voice-over:before {
  content: "\eb1b";
}

.icon-sanitizer:before {
  content: "\eb1c";
}

.icon-suitcase:before {
  content: "\eb1d";
}

.icon-close-1:before {
  content: "\eb1f";
}

.icon-close-2:before {
  content: "\e906";
}

.icon-link:before {
  content: "\e9cb";
}

.icon-asterisk:before {
  content: "\eb21";
}

.icon-notes:before {
  content: "\eb25";
}

.icon-clean:before {
  content: "\eb26";
}

.icon-general:before {
  content: "\eb27";
}

.icon-graduation:before {
  content: "\eb28";
}

.icon-video:before {
  content: "\eb29";
}

.icon-thumb:before {
  content: "\eb2a";
}

.icon-tick:before {
  content: "\e919";
}

.icon-paperplane:before {
  content: "\e91c";
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: "Work-Sans", sans-serif;
  font-size: 100%;
  color: #566374;
}

.text-h1 {
  font: 500 1.5rem/2rem "Poppins Medium", sans-serif;
  letter-spacing: 0.1px;
}
@media (min-width: 375px) {
  .text-h1 {
    font-size: 1.75rem;
    line-height: 2.375rem;
  }
}

.text-h2 {
  font: 600 1.125rem/1.625rem "Poppins Semibold", sans-serif;
}
@media (min-width: 375px) {
  .text-h2 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.text-h3 {
  font: 600 1.0625rem/1.5rem "Poppins Semibold", sans-serif;
  letter-spacing: -0.05px;
}
@media (min-width: 375px) {
  .text-h3 {
    font-size: 1.1875rem;
    line-height: 1.625rem;
  }
}

.text-p {
  font: 400 0.9375rem/1.375rem "Work-Sans", sans-serif;
  letter-spacing: -0.5px;
}
@media (min-width: 375px) {
  .text-p {
    font-size: 1.0625rem;
    line-height: 1.5rem;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus, app-notification-permission .input--phone-prefix::before, .input,
.text-input {
  font: 400 1.125rem/1.625rem "Work-Sans", sans-serif;
  letter-spacing: -0.35px;
}

.button,
.text-button {
  font: 600 0.9375rem/1rem "Poppins Semibold", sans-serif;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.form__error, .label,
.text-caption {
  font: 500 0.9375rem/1.25rem "Work-Sans", sans-serif;
  letter-spacing: -0.55px;
}

button,
input,
select,
textarea,
fieldset,
legend,
label,
select {
  font-family: "Work-Sans", sans-serif;
  font-weight: 400;
}

@keyframes shake {
  0% {
    transform: translateX(0.25rem);
  }
  50% {
    transform: translateX(-0.25rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes translateRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes spinLoop {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: translateY(-0.125rem) rotate(0deg);
  }
  to {
    transform: translateY(-0.125rem) rotate(360deg);
  }
}
/* Use:
  .element {
    font-size: rem(20);
  }
*/
.header {
  align-items: center;
  background: linear-gradient(90deg, #4c0631 10%, #840b55 90%);
  box-shadow: 0px 6px 12px rgba(38, 3, 24, 0);
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 5px;
  transition: border-radius 500ms ease-out, box-shadow 500ms ease-out;
  width: 100%;
}
@media (min-width: 375px) {
  .header {
    padding: 20px 20px 20px 20px;
  }
}
.header__navigation[class*=button] {
  border-color: transparent;
  color: #fff;
}
.header__platform {
  border-radius: 3.125rem;
  height: 3.125rem;
  width: 3.125rem;
}
@media (min-width: 375px) {
  .header__platform {
    border-radius: 3.75rem;
    height: 3.75rem;
    width: 3.75rem;
  }
}
.header__platform--cheerfy {
  background: #00aaf1 url("../../../assets/images/logo-squared-stuart.svg") no-repeat center 45%/2rem;
  border-color: #00aaf1;
}
@media (min-width: 375px) {
  .header__platform--cheerfy {
    background-size: 2.375rem;
  }
}
.header__platform--flipdish {
  background: #1813a5 url("../../../assets/images/logo-squared-flipdish.svg") no-repeat center/2.375rem;
  border-color: #1813a5;
}
@media (min-width: 375px) {
  .header__platform--flipdish {
    background-size: 2.625rem;
  }
}
.header__platform--glovo {
  background: #ffc244 url("../../../assets/images/logo-squared-glovo.svg") no-repeat center 45%/2.5rem;
  border-color: #ffc244;
}
@media (min-width: 375px) {
  .header__platform--glovo {
    background-size: 2.875rem;
  }
}
.header__platform--just-eat {
  background: #fb6100 url("../../../assets/images/logo-squared-just-eat.svg") no-repeat center 47%/1.875rem;
  border-color: #fb6100;
}
@media (min-width: 375px) {
  .header__platform--just-eat {
    background-size: 2.125rem;
  }
}
.header__platform--uber-eats {
  background: #262626 url("../../../assets/images/logo-squared-uber-eats.svg") no-repeat center/1.875rem;
  border-color: #262626;
}
@media (min-width: 375px) {
  .header__platform--uber-eats {
    background-size: 2.25rem;
  }
}
.header--animation {
  border-radius: 0 0 2.5rem 2.5rem;
  box-shadow: 0px 6px 12px rgba(38, 3, 24, 0.5);
}

.intro {
  background: #840b55;
  background: linear-gradient(90deg, #4c0631 10%, #840b55 90%);
  border-radius: 0 0 2.5rem 2.5rem;
  color: #fff;
  padding-bottom: calc(3.75rem / 1.10);
  padding-top: 1.75rem;
  position: relative;
}
@media (min-width: 375px) {
  .intro {
    padding-bottom: 3.75rem;
  }
}

.content {
  background-color: #fff;
  box-shadow: 0px 10px 20px #e2edf9;
  border-radius: 25px;
  padding: 32px 20px 32px;
  position: relative;
  transform: translateY(-1.875rem);
}
.content__title {
  color: #a7166f;
}

/* Use:
  .element {
    font-size: rem(20);
  }
*/
.modal__close {
  color: #bec9d9;
  display: block;
  margin: 0 0 0 auto;
  transform: translateY(-12px);
  position: absolute;
  top: 30px;
  right: 20px;
}
@media (min-width: 375px) {
  .modal__close {
    margin: 0 0 16px auto;
  }
}
.modal__close [class*=icon] {
  font-size: 1.75rem;
}
.modal__content {
  background-color: #fff;
  border-radius: 1.25rem;
  box-shadow: 0px 6px 32px #212121;
  margin: auto;
  padding: 42px 20px;
  position: relative;
  text-align: center;
  width: calc(100% - 20px);
}
@media (min-width: 375px) {
  .modal__content {
    width: calc(100% - 30px);
  }
}
@media (min-width: 414px) {
  .modal__content {
    width: calc(100% - 40px);
  }
}
.modal__description {
  color: #566374;
}
.modal__description--order-success {
  background: rgba(0, 187, 126, 0.1);
  border: 4px solid #00bb7e;
  border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 5s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%23c3ebd9' /%3E%3Cstop  offset='100%25' stop-color='%2300bb7e' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E %3C/svg%3E") 1;
  color: #00bb7e;
  display: inline-block;
  font-size: 2.625rem;
  letter-spacing: 2px;
  line-height: 48px;
  margin-bottom: 0.75rem;
  padding: 0.625rem 1.5rem;
  text-align: center;
}
.modal__description--order-warning {
  background: rgba(255, 173, 0, 0.1);
  border: 4px solid #ffad00;
  border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 5s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%23eaddc2' /%3E%3Cstop  offset='100%25' stop-color='%23ffad00' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E %3C/svg%3E") 1;
  color: #ffad00;
  display: inline-block;
  font-size: 2.625rem;
  letter-spacing: 2px;
  line-height: 48px;
  margin-bottom: 0.75rem;
  padding: 0.625rem 1.5rem;
  text-align: center;
}
.modal__qr {
  margin-bottom: 0.9375rem;
}
.modal__qr-container {
  min-width: 90vw;
}

ion-modal {
  --background: transparent;
  --ion-backdrop-color: #212121;
  --backdrop-opacity: 0.7;
  --box-shadow: initial;
}
ion-modal ion-content {
  --background: transparent;
}
ion-modal ion-content::part(scroll) {
  display: flex;
}

.card {
  align-items: center;
  background-color: #f1f8ff;
  border-radius: 1rem;
  color: #536e8e;
  display: flex;
  justify-content: space-between;
  min-height: 6.875rem;
  padding: 0.875rem 0.9375rem;
  width: 100%;
}
.card__button[class*=button] {
  background-color: #bec9d9;
  border-color: #bec9d9;
  border-radius: 1.875rem;
  color: #536e8e;
  height: 1.875rem;
  width: 1.875rem;
}
.card .card__button [class*=icon] {
  font-size: 1rem;
  margin-left: 0.1875rem;
}
.card__button-container {
  flex: 1;
}
.card__description {
  font: 500 0.75rem/0.875rem "Work-Sans", sans-serif;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
@media (min-width: 375px) {
  .card__description {
    font-size: 0.8125rem;
  }
}
@media (min-width: 414px) {
  .card__description {
    font-size: 0.875rem;
  }
}
.card__icon {
  align-items: center;
  border-radius: 2.5rem;
  display: flex;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
}
.card__icon [class*=icon] {
  font-size: 1.25rem;
}
.card__info {
  display: flex;
  flex: 2;
  flex-flow: column;
  height: 100%;
  justify-content: space-between;
  min-height: 5.125rem;
  padding-left: 0.9375rem;
}
.card__order {
  font: 600 1.3125rem/1.375rem "Work-Sans", sans-serif;
  letter-spacing: 0.5px;
  margin-bottom: 18px;
  text-transform: uppercase;
}
.card__progress {
  background: #dce7f4;
  border-radius: 0.375rem;
  height: 0.375rem;
  margin-bottom: 8px;
  width: 100%;
}
.card__progress {
  -webkit-appearance: none;
}
.card__progress::-webkit-progress-bar {
  background-color: #dce7f4;
  border-radius: 0.375rem;
}
.card__progress::-webkit-progress-value {
  background: #00bb7e;
  border-radius: 0.375rem;
  transition: width 0ms ease-out;
}
.card__title {
  font: 600 1.3125rem/1.4375rem "Poppins Semibold", sans-serif;
  margin-bottom: 17px;
}
.card--default .card__icon {
  background-color: #bec9d9;
  color: #fff;
}
.card--default .card__icon [class*=icon] {
  font-size: 2rem;
}
.card--default .card__title {
  color: #97a3b2;
}
.card__ready {
  align-items: center;
  animation: translateRight 950ms ease infinite 950ms;
  background-color: #ffad00;
  border-color: #ffad00;
  border-radius: 1.875rem;
  box-shadow: 0px 5px 10px #cc8a00;
  color: #fff;
  display: flex;
  height: 1.875rem;
  justify-content: center;
  width: 1.875rem;
}
.card--delivered .card__icon {
  background-color: #009de2;
  color: #fff;
}
.card--delivered .card__icon [class*=icon] {
  font-size: 1.375rem;
  margin-right: 0.1875rem;
}
.card--delivered .card__title {
  color: #009de2;
}
.card--error .card__icon {
  background-color: #ff0000;
  color: #fff;
}
.card--error .card__icon [class*=icon] {
  font-size: 1.375rem;
}
.card--error .card__title {
  color: #ff0000;
}
.card--progress .card__button[class*=button] {
  background-color: #ff0000;
  border-color: #ff0000;
  color: #fff;
}
.card--progress .card__button [class*=icon] {
  color: #fff;
}
.card--progress .card__icon {
  animation: spinLoop 1s linear infinite;
  background-color: #ffad00;
  color: #fff;
}
.card--progress .card__icon [class*=icon] {
  font-size: 1.375rem;
}
.card--progress .card__title {
  color: #ffad00;
}
.card--ready {
  background-color: #00bb7e;
  color: #fff;
}
.card--ready .card__button {
  animation: translateRight 950ms ease infinite 950ms;
  box-shadow: 0px 5px 10px #00885c;
}
.card--ready .card__button[class*=button] {
  background-color: #fff;
  border-color: #fff;
  color: #00bb7e;
}
.card--ready .card__button [class*=icon] {
  color: #00bb7e;
}
.card--ready .card__icon {
  background-color: #00bb7e;
  border: 2px solid #fff;
  color: #fff;
}

.button {
  align-items: center;
  border-radius: 0.625rem;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 1.375rem 1.25rem 1.25rem;
  position: relative;
  text-transform: uppercase;
  transition: background-color 150ms ease-in-out;
  transition: border-color 150ms ease-in-out, color 150ms ease-in-out;
}
.button[disabled], .button.disabled {
  pointer-events: none;
}
.button__icon-left {
  margin-right: 0.625rem;
}
.button__icon-right {
  margin-left: 0.625rem;
}
.button__brand {
  height: 3.75rem;
  padding: 0;
  width: 100%;
}
.button__brand--cheerfy {
  background: #00aaf1 url("../../../assets/images/logo-inline-stuart.svg") no-repeat center/6.875rem;
  border-color: #008dc8;
}
.button__brand--flipdish {
  background: #1813a5 url("../../../assets/images/logo-inline-flipdish.svg") no-repeat center/8.125rem;
  border-color: #04031c;
}
.button__brand--glovo {
  background: #ffc244 url("../../../assets/images/logo-inline-glovo.svg") no-repeat center 0.375rem/6.875rem;
  border-color: #ffb111;
  padding-bottom: 0.75rem;
}
.button__brand--just-eat {
  background: #fb6100 url("../../../assets/images/logo-inline-just-eat.svg") no-repeat center 0.5rem/8.75rem;
  border-color: #c84d00;
}
.button__brand--uber-eats {
  background: #262626 url("../../../assets/images/logo-inline-uber-eats.svg") no-repeat center/8.125rem;
  border-color: black;
}
.button__sticky-wrapper {
  background: linear-gradient(0deg, #fff 75%, rgba(255, 255, 255, 0) 100%);
  bottom: 0;
  left: 0;
  padding: 40px 20px 20px;
  position: sticky;
  right: 0;
}
@media (min-width: 375px) {
  .button__sticky-wrapper {
    padding: 64px 30px 30px;
  }
}
@media (min-width: 414px) {
  .button__sticky-wrapper {
    padding: 64px 40px 40px;
  }
}
.button--floating {
  align-items: center;
  background-color: #ffad00;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 6px 8px #e2edf9;
  color: #fff;
  display: flex;
  height: 3.75rem;
  justify-content: center;
  margin-left: auto;
  padding: 0;
  width: 3.75rem;
}
.button--floating:active {
  background-color: #996800;
  border-color: #332300;
}
.button--floating [class*=icon] {
  font-size: 1.875rem;
}
.button--floating[disabled], .button--floating.disabled {
  background-color: #bec9d9;
}
.button--icon {
  padding: 1.1875rem 1.25rem 1.0625rem;
}
.button--icon .button__icon-left,
.button--icon .button__icon-right {
  font-size: 1.25rem;
  transform: translateY(-0.125rem);
}
.button--navigation {
  background-color: transparent;
  border: 2px solid #ff1070;
  border-radius: 50%;
  color: #ff1070;
  height: 3.75rem;
  width: 3.75rem;
  padding: 0.8125rem;
}
.button--navigation:active {
  border-color: #a90044;
}
.button--navigation [class*=icon] {
  font-size: 1.5rem;
}
.button--progress {
  border-radius: 50%;
  height: 3.75rem;
  width: 3.75rem;
  padding: 0.8125rem;
}
.button--progress [class*=icon] {
  font-size: 1.75rem;
}
.button--outlined {
  background-color: transparent;
  border: 2px solid #ffe6f0;
  color: #ff1070;
  width: 100%;
}
.button--outlined:active {
  background-color: #ffe6f0;
}
.button--outlined[disabled], .button--outlined.disabled {
  background-color: #bec9d9;
  border-color: #bec9d9;
}
.button--primary {
  background-color: #ff1070;
  border: 1px solid #a90044;
  color: #fff;
  width: 100%;
}
.button--primary:active {
  background-color: #a90044;
  border-color: #43001b;
}
.button--primary[disabled], .button--primary.disabled {
  background-color: #bec9d9;
  border-color: #a4b4ca;
}
.button--secondary {
  background-color: #840b55;
  border: 1px solid #260318;
  color: #fff;
  width: 100%;
}
.button--secondary:active {
  background-color: #260318;
  border-color: black;
}
.button--secondary[disabled], .button--secondary.disabled {
  background-color: #bec9d9;
  border-color: #a4b4ca;
}
.button--terciary {
  background-color: transparent;
  border: none;
  color: #ff1070;
  display: block;
  margin: 0 auto 0;
  padding: 0.375rem 0.75rem 0.25rem;
  text-decoration: underline;
  width: auto;
}
.button--terciary:active {
  color: #a90044;
}
.button--terciary[disabled], .button--terciary.disabled {
  color: #bec9d9;
}

.input {
  -webkit-appearance: none;
  appearance: none;
  background-color: #eaf3fd;
  border: 2px solid #eaf3fd;
  border-radius: 0.75rem;
  padding: 16px 14px;
  transition: border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
  width: 100%;
}
.input::placeholder {
  color: #a2b4cb;
  transition: color 150ms ease-in-out;
}
.input[class*=invalid][class*=ng-touched] {
  border-color: #ff0000;
  box-shadow: 0px 0px 0px 1px #ff0000;
}
app-notification-permission .input--phone-prefix {
  display: block;
  position: relative;
}
app-notification-permission .input--phone-prefix::before {
  align-items: center;
  background-color: #fff;
  border-radius: 0.75rem 0 0 0.75rem;
  border: 2px solid #eaf3fd;
  box-shadow: -2px 0px 0px 2px #fff;
  color: #566374;
  content: "+34";
  display: flex;
  height: 3.875rem;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 3.875rem;
}
app-notification-permission .input--phone-prefix [class*=input] {
  padding-left: 4.625rem;
}
app-notification-permission [class*=invalid][class*=ng-touched] .input--phone-prefix::before {
  border-right-color: #ff0000;
}
app-notification-permission [class*=invalid][class*=ng-touched] .input--phone-prefix [class*=error] {
  padding-left: 3.75rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 2px solid #840b55;
  transition: background-color 150ms ease-in-out;
  -webkit-box-shadow: 0 0 0px 1000px #f698d2 inset;
  -webkit-text-fill-color: #840b55;
}

.label {
  color: #566374;
  display: block;
  margin-bottom: 4px;
  text-transform: uppercase;
}

ion-backdrop {
  --backdrop-opacity: 0.7;
}

ion-loading .loading-wrapper[class*=ios], ion-loading .loading-wrapper[class*=md] {
  --background: transparent;
  box-shadow: none;
}

ion-spinner {
  width: 40px;
  height: 40px;
}
ion-spinner[class*=ios], ion-spinner[class*=md] {
  color: #ffad00;
}

.chip {
  align-items: center;
  border-radius: 1.875rem;
  cursor: pointer;
  display: inline-flex;
  font: 500 0.875rem/1.125rem "Poppins Semibold", sans-serif;
  justify-content: center;
  letter-spacing: 0;
  padding: 0.375rem 1rem;
  position: relative;
  text-transform: uppercase;
  transition: background-color 150ms ease-in-out;
}
@media (min-width: 375px) {
  .chip {
    font-size: 1rem;
    padding: 0.5rem 1.125rem;
  }
}
.chip[disabled], .chip.disabled {
  pointer-events: none;
}
.chip__icon-left {
  margin-right: 0.375rem;
}
.chip__icon-right {
  margin-left: 0.375rem;
}
.chip--icon .chip__icon-left,
.chip--icon .chip__icon-right {
  font-size: 1rem;
  transform: translateY(-0.125rem);
}
@media (min-width: 375px) {
  .chip--icon .chip__icon-left,
.chip--icon .chip__icon-right {
    font-size: 1.125rem;
  }
}
.chip--primary {
  background-color: #ffad00;
  border: 1px solid #cc8a00;
  color: #fff;
}
.chip--primary[disabled], .chip--primary.disabled {
  background-color: #bec9d9;
  border-color: #bec9d9;
}

.checkbox {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}
.checkbox-checked + .checkbox__label::before {
  background-color: #7d93ae;
  border-color: #7d93ae;
}
.checkbox-checked + .checkbox__label::after {
  opacity: 1;
}
.checkbox__input {
  bottom: 0;
  height: 1.625rem;
  left: 0;
  margin: 0;
  min-width: 1.625rem;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}
.checkbox__input[disabled] + .checkbox__label {
  color: #bec9d9;
  pointer-events: none;
}
.checkbox__input[disabled] + .checkbox__label::before {
  background-color: #bec9d9;
  border: 0;
}
.checkbox__input:checked + .checkbox__label::before {
  background-color: #7d93ae;
  border-color: #7d93ae;
}
.checkbox__input:checked + .checkbox__label::after {
  opacity: 1;
}
.checkbox__input:active + .checkbox__label::before {
  border-color: #5f7999;
}
.checkbox__input-error + .checkbox__label::before {
  border-color: #ff0000;
  box-shadow: 0px 0px 0px 1px #ff0000;
}
.checkbox__label {
  color: #566374;
  display: inline-block;
  font: 400 0.9375rem/1.375rem "Work-Sans", sans-serif;
  letter-spacing: -0.5px;
  margin-top: 0.125rem;
  padding: 0.125rem 0 0 2.1875rem;
  pointer-events: none;
  position: relative;
  text-align: left;
  z-index: 11;
}
.checkbox__label::before {
  background-color: #fff;
  border: 2px solid #eaf3fd;
  border-radius: 4px;
  content: "";
  display: block;
  height: 1.625rem;
  left: 0;
  pointer-events: none;
  position: absolute;
  transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out;
  top: -0.0625rem;
  width: 1.625rem;
  z-index: 0;
}
.checkbox__label::after {
  border: 3px solid #fff;
  border-radius: 1px;
  border-right: 0;
  border-top: 0;
  content: "";
  height: 0.625rem;
  left: 0.3125rem;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0.3125rem;
  transform: rotate(-45deg);
  transition: opacity 150ms ease-in-out;
  width: 1rem;
}
.checkbox__link {
  color: #ff1070;
  pointer-events: all;
  text-align: left;
  text-decoration: underline;
}

.form__error {
  animation: shake 0.15s linear 2 forwards;
  color: #ff0000;
  margin-top: 4px;
}

.form__error {
  text-align: initial;
}
.form__input {
  display: inline-block;
  width: 100%;
}