html {
  overflow-x: hidden;
  
  &.locked {
    height: 100vh;
    overflow: hidden;
    width: 100vw;
  }
}

body {
  --ion-background-color: #{$color-bg};
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  padding: 0 $wrapper-padding-sm;
  width: 100%;

  @include mobile-small {
    padding: 0 $wrapper-padding-md;
  }

  @include mobile-medium {
    padding: 0 $wrapper-padding-lg;
  }
}

:focus {
  outline: none;
  box-shadow: 0 0 0 $border-outline rgba($color-tertiary, 0);
}
