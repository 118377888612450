* {
  margin: 0;
  padding: 0;

  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

ol,
ul {
  list-style-type: none;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

span {
  display: inline-block;
}

a,
button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background: none;
  border: 0;
}
