@import './../../../theme/rider/base/variables';

.content {
  background-color: $color-bg;
  box-shadow: 0px 10px 20px $color-grey-light;
  border-radius: 25px;
  padding: 32px 20px 32px;
  position: relative;
  transform: translateY(-#{calc(rem($intro-background-gap) / 2) });

  &__title {
    color: $color-purple;
  }
}
