.card {
  $el: &;
  align-items: center;
  background-color: $color-ui-25;
  border-radius: rem($radius-card);
  color: $color-detail;
  display: flex;
  justify-content: space-between;
  min-height: rem(110);
  padding: rem(14) rem(15);
  width: 100%;

  &__button {
    &[class*="button"] {
      background-color: $color-grey;
      border-color: $color-grey;
      border-radius: rem(30);
      color: $color-detail;
      height: rem(30);
      width: rem(30);
    }

    [class*="icon"] {
      #{$el} & {
        font-size: rem(16);
        margin-left: rem(3);
      }
    }

    &-container{
      flex: 1;
    }
  }
  
  &__description {
    font: $font-medium #{rem(12)} / #{rem(14)} $font-text;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    @include mobile-small {
      font-size: rem(13);
    }   
    
    @include mobile-medium {
      font-size: rem(14);
    }
  }

  &__icon {
    align-items: center;
    border-radius: rem(40);
    display: flex;
    justify-content: center;
    height: rem(40);
    width: rem(40);

    [class*="icon"]  {
      font-size: rem(20);
    }
  }

  &__info {
    display: flex;
    flex: 2;
    flex-flow: column;
    height: 100%;
    justify-content: space-between;
    min-height: rem(82);
    padding-left: rem(15);
  }

  &__order {
    font: $font-semibold #{rem(21)} / #{rem(22)} $font-text;
    letter-spacing: 0.5px;
    margin-bottom: 18px;
    text-transform: uppercase;
  }

  &__progress {
    background: $color-ui-100;
    border-radius: rem(6);
    height: rem(6);
    margin-bottom: $stack-sm;
    width: 100%;
  }
  
  &__progress {
    -webkit-appearance: none;

    &::-webkit-progress-bar {
      background-color: $color-ui-100;
      border-radius: rem(6);
    }

    &::-webkit-progress-value {
      background: $color-success;
      border-radius: rem(6);
      transition: width $transition-delay ease-out;
    }
  }
  
  &__title {
    font: $font-semibold #{rem(21)} / #{rem(23)} $font-subtitle;
    margin-bottom: 17px;
  }

  &--default {
    #{$el}__icon {
      background-color: $color-grey;
      color: $color-white;
      
      [class*="icon"] {
        font-size: rem(32);
      }
    }

    #{$el}__title {
      color: lighten($color-grey-dark, 25%);
    }
  }

  &__ready{
    align-items: center;
    animation: translateRight 950ms ease infinite 950ms;
    background-color: $color-warning;
    border-color: $color-warning;
    border-radius: rem(30);
    box-shadow: 0px 5px 10px darken($color-warning, 10%);
    color: $color-white;
    display: flex;
    height: rem(30);
    justify-content: center;
    width: rem(30);
  }

  &--delivered {
    #{$el}__icon {
      background-color: $color-info;
      color: $color-white;
      
      [class*="icon"] {
        font-size: rem(22);
        margin-right: rem(3);
      }
    }

    #{$el}__title {
      color: $color-info;
    }
  }

  &--error {
    #{$el}__icon {
      background-color: $color-negative;
      color: $color-white;
      
      [class*="icon"] {
        font-size: rem(22);
      }
    }

    #{$el}__title {
      color: $color-negative;
    }
  }

  &--progress {
    #{$el}__button {

      &[class*="button"] {
        background-color: $color-negative;
        border-color: $color-negative;
        color: $color-white;
      }
  
      [class*="icon"] {
        color: $color-white;
      }
    }

    #{$el}__icon {
      animation: spinLoop 1s linear infinite;
      background-color: $color-warning;
      color: $color-white;
      
      [class*="icon"] {
        font-size: rem(22);
      }
    }

    #{$el}__title {
      color: $color-warning;
    }
  }

  &--ready {
    background-color: $color-positive;
    color: $color-text--invert;

    #{$el}__button {
      animation: translateRight 950ms ease infinite 950ms;
      box-shadow: 0px 5px 10px darken($color-positive, 10%);

      &[class*="button"] {
        background-color: $color-white;
        border-color: $color-white;
        color: $color-positive;
      }
  
      [class*="icon"] {
        color: $color-positive;
      }
    }

    #{$el}__icon {
      background-color: $color-positive;
      border: $border-icon solid $color-white;
      color: $color-white;
    }
  }
}