.chip {
  $el: &;

  align-items: center;
  border-radius: rem(30);
  cursor: pointer;
  display: inline-flex;
  font: $font-medium #{rem(14)} / #{rem(18)} $font-button;
  justify-content: center;
  letter-spacing: 0;
  padding: rem(6) rem(16);
  position: relative;
  text-transform: uppercase;
  transition: background-color $transition-hover ease-in-out;
  
  @include mobile-small {
    font-size: rem(16);
    padding: rem(8) rem(18);
  }

  &[disabled],
  &.disabled {
    pointer-events: none;
  }

  &__icon-left {
    margin-right: rem(6);
  }

  &__icon-right {
    margin-left: rem(6);
  }

  &--icon {
    #{$el}__icon-left,
    #{$el}__icon-right {
      font-size: rem(16);
      transform: translateY(#{rem(-2)});

      @include mobile-small {
        font-size: rem(18);
      }
    }  
  }

  &--primary {
    background-color: $color-tertiary;
    border: $border solid darken($color-tertiary, 10%);
    color: $color-white;

    &[disabled],
    &.disabled { 
      background-color: $color-disabled;
      border-color: $color-disabled;
    }
  }
}
