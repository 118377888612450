.input {
  $el: &;
  
  @extend %text-input;
  -webkit-appearance: none;
  appearance: none;
  background-color: $color-ui-50;
  border: $border-input solid $color-ui-50;
  border-radius: rem($radius-input);
  padding: $inset-md;
  transition: border-color $transition-hover ease-in-out, box-shadow $transition-hover ease-in-out;
  width: 100%;

  &::placeholder {
    color: $color-placeholder;
    transition: color $transition-hover ease-in-out;
  }

  &[class*='invalid'][class*='ng-touched'] {
    border-color: $color-negative;
    box-shadow: 0px 0px 0px 1px $color-error;
  }

  &--phone-prefix {
    app-notification-permission & {
      display: block;
      position: relative;

      &::before {
        @extend %text-input;
        align-items: center;
        background-color: $color-bg;
        border-radius: rem($radius-input) 0 0 rem($radius-input);
        border: $border-input solid $color-ui-50;
        box-shadow: -$border-input 0px 0px $border-input $color-white;
        color: $color-grey-dark;
        content: '+34';
        display: flex;
        height: rem(62);
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: rem(62);
      }

      [class*="input"] {
        padding-left: rem(74);
      }
    }
     
    app-notification-permission [class*='invalid'][class*='ng-touched'] & {             
      &::before {
        border-right-color: $color-negative;
      }

      [class*="error"] {
        padding-left: rem(60);
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  @extend %text-input;
  border: $border-input solid $color-secondary;
  transition: background-color $transition-hover ease-in-out;
  -webkit-box-shadow: 0 0 0px 1000px lighten($color-secondary, 50%) inset;
  -webkit-text-fill-color: $color-secondary;
}