@import './../../../theme/rider/base/variables';
@import './../../../theme/rider/base/mixins';

.modal {
  &__close {
    color: $color-grey;
    display: block;
    margin: 0 0 0 auto;
    transform: translateY(-12px);
    position: absolute;
    top: 30px;
    right: 20px;
    
    @include mobile-small {
      margin: 0 0 $stack-md auto;
    }

    [class*="icon"] {
      font-size: rem(28);
    }
  }

  &__content {
    background-color: $color-bg;
    border-radius: rem($radius-modal);
    box-shadow: 0px 6px 32px $color-black;
    margin: auto;
    padding: 42px 20px;
    position: relative;
    text-align: center;
    width: calc(100% - $wrapper-padding-sm);

    @include mobile-small {
      width: calc(100% - $wrapper-padding-md);
    }

    @include mobile-medium {
      width: calc(100% - $wrapper-padding-lg);
    }
  }

  &__description  {
    color: $color-grey-dark;
  }

  &__description--order-success {
    background: rgba($color-success, 0.1);
    border: 4px solid $color-green;
    border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 5s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%23c3ebd9' /%3E%3Cstop  offset='100%25' stop-color='%2300bb7e' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E %3C/svg%3E") 1;
    color: $color-success;
    display: inline-block;
    font-size: rem(42);
    letter-spacing: 2px;
    line-height: 48px;
    margin-bottom: rem(12);
    padding: rem(10) rem(24);
    text-align: center;
  }

  &__description--order-warning {
    background: rgba($color-warning, 0.1);
    border: 4px solid $color-warning;
    border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 5s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%23eaddc2' /%3E%3Cstop  offset='100%25' stop-color='%23ffad00' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E %3C/svg%3E") 1;
    color: $color-warning;
    display: inline-block;
    font-size: rem(42);
    letter-spacing: 2px;
    line-height: 48px;
    margin-bottom: rem(12);
    padding: rem(10) rem(24);
    text-align: center;
  }

  &__qr {
    margin-bottom: rem(15);

    &-container {
      min-width: 90vw;
    }
  }
}

ion-modal {
  --background: transparent;
  --ion-backdrop-color: #{$color-backdrop};
  --backdrop-opacity: #{$backdrop-opacity};
  --box-shadow: initial;

  ion-content {
    --background: transparent;

    &::part(scroll) {
        display: flex;
    }
  }
}
