.button {
  $el: &;
  @extend %text-button;
  align-items: center;
  border-radius: rem($radius-button);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: rem(22) rem(20) rem(20);
  position: relative;
  text-transform: uppercase;
  transition: background-color $transition-hover ease-in-out;
  transition: border-color $transition-hover ease-in-out, color $transition-hover ease-in-out;

  &[disabled],
  &.disabled {
    pointer-events: none;
  }

  &__icon-left {
    margin-right: rem(10);
  }

  &__icon-right {
    margin-left: rem(10);
  }

  &__brand {
    height: rem(60);
    padding: 0;
    width: 100%;
  }

  &__brand--cheerfy {
    background: $color-cheerfy url('../../../assets/images/logo-inline-stuart.svg') no-repeat center / #{rem(110)};
    border-color: darken($color-cheerfy, 8%);
  }

  &__brand--flipdish {
    background: $color-flipdish url('../../../assets/images/logo-inline-flipdish.svg') no-repeat center / #{rem(130)};
    border-color: darken($color-flipdish, 30%);
  }

  &__brand--glovo {
    background: $color-glovo url('../../../assets/images/logo-inline-glovo.svg') no-repeat center #{rem(6)} / #{rem(110)};
    border-color: darken($color-glovo, 10%);
    padding-bottom: rem(12);
  }

  &__brand--just-eat {
    background: $color-just-eat url('../../../assets/images/logo-inline-just-eat.svg') no-repeat center #{rem(8)} / #{rem(140)};
    border-color: darken($color-just-eat, 10%);
  }

  &__brand--uber-eats {
    background: $color-uber-eats url('../../../assets/images/logo-inline-uber-eats.svg') no-repeat center / #{rem(130)};
    border-color: darken($color-uber-eats, 30%);
  }

  &__sticky-wrapper {
    background: linear-gradient(0deg, $color-white 75%, #ffffff00 100%);
    bottom: 0;
    left: 0;
    padding: $wrapper-padding-lg $wrapper-padding-sm $wrapper-padding-sm;
    position: sticky;
    right: 0;

    @include mobile-small {
      padding: $stack-xxl $wrapper-padding-md $wrapper-padding-md;
    }

    @include mobile-medium {
      padding: $stack-xxl $wrapper-padding-lg $wrapper-padding-lg;
    }
  }

  &--floating {
    align-items: center;
    background-color: $color-tertiary;
    border: none;
    border-radius: 50%;
    box-shadow: 0px 6px 8px $color-grey-light;
    color: $color-white;
    display: flex;
    height: rem(60);
    justify-content: center;
    margin-left: auto;
    padding: 0;
    width: rem(60);

    &:active {
      background-color: $color-tertiary-accent;
      border-color: darken($color-tertiary-accent, 20%);
    }

    [class*='icon'] {
      font-size: rem(30);
    }

    &[disabled],
    &.disabled { 
      background-color: $color-disabled;
    }
  }

  &--icon {
    padding: rem(19) rem(20) rem(17);

    #{$el}__icon-left,
    #{$el}__icon-right {
      font-size: rem(20);
      transform: translateY(#{rem(-2)});
    }  
  }

  &--navigation {
    background-color: transparent;
    border: 2px solid $color-primary;
    border-radius: 50%;
    color: $color-primary;
    height: rem(60);
    width: rem(60);
    padding: rem(13);

    &:active {
      border-color: $color-primary-accent;
    }

    [class*='icon'] {
      font-size: rem(24);
    }
  }

  &--progress {
    border-radius: 50%;
    height: rem(60);
    width: rem(60);
    padding: rem(13);

    [class*='icon'] {
      font-size: rem(28);
    }
  }

  &--outlined {
    background-color: transparent;
    border: $border-button solid lighten($color-primary, 42%);
    color: $color-primary;
    width: 100%;

    &:active {
      background-color: lighten($color-primary, 42%);
    }

    &[disabled],
    &.disabled { 
      background-color: $color-disabled;
      border-color: $color-disabled;
    }
  }

  &--primary {
    background-color: $color-primary;
    border: $border solid $color-primary-accent;
    color: $color-text--invert;
    width: 100%;

    &:active {
      background-color: $color-primary-accent;
      border-color: darken($color-primary-accent, 20%);
    }

    &[disabled],
    &.disabled { 
      background-color: $color-disabled;
      border-color: darken($color-disabled, 8%);
    }
  }
  
  &--secondary {
    background-color: $color-secondary;
    border: $border solid $color-secondary-accent;
    color: $color-text--invert;
    width: 100%;

    &:active {
      background-color: $color-secondary-accent;
      border-color: darken($color-secondary-accent, 20%);
    }

    &[disabled],
    &.disabled { 
      background-color: $color-disabled;
      border-color: darken($color-disabled, 8%);
    }
  }

  &--terciary {
    background-color: transparent;
    border: none;
    color: $color-primary;
    display: block;
    margin: 0 auto 0;
    padding: rem(6) rem(12) rem(4);
    text-decoration: underline;
    width: auto;

    &:active {
      color: $color-primary-accent;
    }

    &[disabled],
    &.disabled { 
      color: $color-disabled;
    }
  }
}
