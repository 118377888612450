@import './../../../theme/rider/base/variables';
 @import './../base/variables';

// .loader {
//     --backdrop-opacity: #{$backdrop-opacity};
//     --background: #840b55;
//     --spinner-color:#ffffff;
//  }

ion-backdrop {
    --backdrop-opacity: #{$backdrop-opacity};
}

ion-loading {
    .loading-wrapper {
        &[class*="ios"],
        &[class*="md"] {
            --background: transparent;
            box-shadow: none;
        }
    }
}

ion-spinner {
    width: 40px;
    height: 40px;

    &[class*="ios"],
    &[class*="md"] {
        color: #{$color-tertiary};
    }
}