$icomoon-font-family: 'icons' !default;
$icomoon-font-path: './../../../assets/fonts/' !default;

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?hru9ow') format('woff'), url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?hru9ow') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

%custom-icon,
[class^='icon-'],
[class*=' icon-'] {
  font-family: $icomoon-font-family !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: rem(16);
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icon-home-1: '\e900';
$icon-home-2: '\e902';
$icon-office: '\e903';
$icon-newspaper: '\e904';
$icon-pencil: '\e905';
$icon-quill: '\e907';
$icon-pen-1: '\e908';
$icon-pen-2: '\e91d';
$icon-eyedropper: '\e90a';
$icon-droplet: '\e90b';
$icon-paint-format: '\e90c';
$icon-image: '\e90d';
$icon-gallery: '\e90e';
$icon-camera: '\e90f';
$icon-headphones: '\e910';
$icon-music: '\e911';
$icon-bullhorn: '\e91a';
$icon-connection: '\e91b';
$icon-mic: '\e91e';
$icon-book-1: '\e91f';
$icon-book-2: '\eb0d';
$icon-books: '\e920';
$icon-library: '\e921';
$icon-file-empty: '\e924';
$icon-files-empty: '\e925';
$icon-file-text: '\e926';
$icon-file-picture: '\e927';
$icon-file-music: '\e928';
$icon-file-play: '\e929';
$icon-file-video: '\e92a';
$icon-file-zip: '\e92b';
$icon-copy: '\e92c';
$icon-paste: '\e92d';
$icon-stack: '\e92e';
$icon-folder: '\e92f';
$icon-folder-open: '\e930';
$icon-folder-plus: '\e931';
$icon-folder-minus: '\e932';
$icon-folder-download: '\e933';
$icon-folder-upload: '\e934';
$icon-price-tag: '\e935';
$icon-price-tags: '\e936';
$icon-barcode: '\e937';
$icon-qrcode: '\e938';
$icon-ticket: '\e939';
$icon-cart: '\e93a';
$icon-coin-dollar: '\e93b';
$icon-coin-euro: '\e93c';
$icon-coin-pound: '\e93d';
$icon-coin-yen: '\e93e';
$icon-credit-card: '\e93f';
$icon-calculator: '\e940';
$icon-lifebuoy: '\e941';
$icon-phone: '\e942';
$icon-phone-hang-up: '\e943';
$icon-address-book: '\e944';
$icon-envelop: '\e945';
$icon-pushpin: '\e946';
$icon-location-1: '\e947';
$icon-location-2: '\e948';
$icon-compass: '\e949';
$icon-compass2: '\e94a';
$icon-map-1: '\e94b';
$icon-map-2: '\e94c';
$icon-history: '\e94d';
$icon-clock-1: '\e94e';
$icon-clock-2: '\e94f';
$icon-alarm: '\e950';
$icon-bell: '\e951';
$icon-stop-watch: '\e952';
$icon-calendar-1: '\e953';
$icon-calendar-2: '\eafe';
$icon-calendar-3: '\eb24';
$icon-printer: '\e954';
$icon-key-board: '\e955';
$icon-display: '\e956';
$icon-laptop-1: '\e957';
$icon-laptop-2: '\eb19';
$icon-mobile-1: '\e958';
$icon-mobile-2: '\e959';
$icon-tablet: '\e95a';
$icon-tv: '\e95b';
$icon-drawer-1: '\e95c';
$icon-drawer-2: '\e95d';
$icon-box-add: '\e95e';
$icon-box-remove: '\e95f';
$icon-download-1: '\e960';
$icon-download-2: '\e9c5';
$icon-download-3: '\e9c7';
$icon-download-4: '\eb22';
$icon-upload-1: '\e961';
$icon-upload-2: '\e9c6';
$icon-upload-3: '\e9c8';
$icon-floppy-disk: '\e962';
$icon-drive: '\e963';
$icon-database: '\e964';
$icon-undo-1: '\e965';
$icon-undo-2: '\e967';
$icon-redo-1: '\e966';
$icon-redo-2: '\e968';
$icon-reply: '\e96a';
$icon-bubble-1: '\e96b';
$icon-bubble-2: '\e96e';
$icon-bubbles-1: '\e96c';
$icon-bubbles-2: '\e96d';
$icon-bubbles-3: '\e96f';
$icon-bubbles-4: '\e970';
$icon-user: '\e971';
$icon-users: '\e972';
$icon-user-plus: '\e973';
$icon-user-minus: '\e974';
$icon-user-check: '\e975';
$icon-user-tie: '\e976';
$icon-quotes-left: '\e977';
$icon-quotes-right: '\e978';
$icon-hour-glass: '\e979';
$icon-spinner-1: '\e97a';
$icon-spinner-2: '\e97b';
$icon-spinner-3: '\e97c';
$icon-spinner-4: '\e97d';
$icon-spinner-5: '\e97e';
$icon-spinner-6: '\e97f';
$icon-spinner-7: '\e980';
$icon-spinner-8: '\e981';
$icon-spinner-9: '\e982';
$icon-spinner-10: '\e983';
$icon-spinner-11: '\e984';
$icon-spinner-12: '\e917';
$icon-binoculars: '\e985';
$icon-search-1: '\e986';
$icon-search-2: '\eafb';
$icon-zoom-in: '\e987';
$icon-zoom-out: '\e988';
$icon-enlarge: '\e989';
$icon-shrink: '\e98a';
$icon-enlarge2: '\e98b';
$icon-shrink2: '\e98c';
$icon-key-1: '\e98d';
$icon-key-2: '\e98e';
$icon-lock: '\e98f';
$icon-unlocked: '\e990';
$icon-wrench: '\e991';
$icon-equalizer-1: '\e992';
$icon-equalizer-2: '\e993';
$icon-cog: '\e994';
$icon-cogs: '\e995';
$icon-hammer-1: '\e996';
$icon-hammer-2: '\e9a8';
$icon-magic-wand: '\e997';
$icon-aid-kit: '\e998';
$icon-bug: '\e999';
$icon-pie-chart: '\e99a';
$icon-stats-dots: '\e99b';
$icon-stats-bars-1: '\e99c';
$icon-stats-bars-2: '\e99d';
$icon-trophy: '\e99e';
$icon-gift: '\e99f';
$icon-glass-1: '\e9a0';
$icon-glass-2: '\e9a1';
$icon-mug: '\e9a2';
$icon-spoon-knife: '\e9a3';
$icon-leaf: '\e9a4';
$icon-rocket-1: '\e9a5';
$icon-rocket-2: '\eb0f';
$icon-meter-1: '\e9a6';
$icon-meter-2: '\e9a7';
$icon-fire: '\e9a9';
$icon-lab: '\e9aa';
$icon-magnet: '\e9ab';
$icon-bin-1: '\e9ac';
$icon-bin-2: '\e9ad';
$icon-briefcase: '\e9ae';
$icon-airplane: '\e9af';
$icon-truck: '\e9b0';
$icon-road: '\e9b1';
$icon-accessibility: '\e9b2';
$icon-target: '\e9b3';
$icon-shield: '\e9b4';
$icon-power: '\e9b5';
$icon-switch: '\e9b6';
$icon-power-cord: '\e9b7';
$icon-clipboard: '\e9b8';
$icon-list-numbered: '\e9b9';
$icon-list-1: '\e9ba';
$icon-list-2: '\e9bb';
$icon-tree: '\e9bc';
$icon-menu-1: '\e9bd';
$icon-menu-2: '\e9be';
$icon-menu-3: '\e9bf';
$icon-menu-4: '\e9c0';
$icon-menu-5: '\eafa';
$icon-cloud: '\e9c1';
$icon-cloud-download: '\e9c2';
$icon-cloud-upload: '\e9c3';
$icon-cloud-check: '\e9c4';
$icon-sphere: '\e9c9';
$icon-earth: '\e9ca';
$icon-link: '\e9cb';
$icon-flag: '\e9cc';
$icon-attachment: '\e9cd';
$icon-eye: '\e9ce';
$icon-eye-plus: '\e9cf';
$icon-eye-minus: '\e9d0';
$icon-eye-blocked: '\e9d1';
$icon-bookmark: '\e9d2';
$icon-bookmarks: '\e9d3';
$icon-sun: '\e9d4';
$icon-contrast: '\e9d5';
$icon-brightness-contrast: '\e9d6';
$icon-star-empty: '\e9d7';
$icon-star-half: '\e9d8';
$icon-star-full: '\e9d9';
$icon-heart: '\e9da';
$icon-heart-broken: '\e9db';
$icon-man: '\e9dc';
$icon-woman: '\e9dd';
$icon-man-woman: '\e9de';
$icon-point-up: '\ea03';
$icon-point-right: '\ea04';
$icon-point-down: '\ea05';
$icon-point-left: '\ea06';
$icon-warning: '\ea07';
$icon-notification: '\ea08';
$icon-question: '\ea09';
$icon-plus-1: '\ea0a';
$icon-plus-2: '\eafc';
$icon-plus-3: '\e901';
$icon-minus: '\ea0b';
$icon-info: '\ea0c';
$icon-cancel-circle: '\ea0d';
$icon-blocked: '\ea0e';
$icon-cross-1: '\ea0f';
$icon-cross-2: '\e916';
$icon-checkmark-1: '\ea10';
$icon-checkmark-2: '\ea11';
$icon-spell-check: '\ea12';
$icon-enter: '\ea13';
$icon-exit: '\ea14';
$icon-play-1: '\e912';
$icon-play-2: '\ea15';
$icon-play-3: '\ea1c';
$icon-pause-1: '\ea16';
$icon-pause-2: '\ea1d';
$icon-stop-1: '\ea17';
$icon-stop-2: '\ea1e';
$icon-previous-1: '\ea18';
$icon-previous-2: '\ea23';
$icon-next-1: '\ea19';
$icon-next-2: '\ea24';
$icon-backward-1: '\ea1a';
$icon-backward-2: '\ea1f';
$icon-forward-1: '\e969';
$icon-forward-2: '\ea1b';
$icon-forward-3: '\ea20';
$icon-first: '\ea21';
$icon-last: '\ea22';
$icon-eject: '\ea25';
$icon-volume-high: '\ea26';
$icon-volume-medium: '\ea27';
$icon-volume-low: '\ea28';
$icon-volume-mute-1: '\ea29';
$icon-volume-mute-2: '\ea2a';
$icon-volume-increase: '\ea2b';
$icon-volume-decrease: '\ea2c';
$icon-loop-1: '\ea2d';
$icon-loop-2: '\ea2e';
$icon-infinite: '\ea2f';
$icon-shuffle: '\ea30';
$icon-arrow-up-left-1: '\ea31';
$icon-arrow-up-left-2: '\ea39';
$icon-arrow-up-1: '\ea32';
$icon-arrow-up-2: '\ea3a';
$icon-arrow-up-3: '\eaef';
$icon-arrow-up-4: '\e909';
$icon-arrow-up-right-1: '\ea33';
$icon-arrow-up-right-2: '\ea3b';
$icon-arrow-right-1: '\ea34';
$icon-arrow-right-2: '\ea3c';
$icon-arrow-right-3: '\eaf0';
$icon-arrow-right-4: '\e913';
$icon-arrow-down-right-1: '\ea35';
$icon-arrow-down-right-2: '\ea3d';
$icon-arrow-down-1: '\ea36';
$icon-arrow-down-2: '\ea3e';
$icon-arrow-down-3: '\eaf1';
$icon-arrow-down-4: '\e914';
$icon-arrow-down-left-1: '\ea37';
$icon-arrow-down-left-2: '\ea3f';
$icon-arrow-left-1: '\ea38';
$icon-arrow-left-2: '\ea40';
$icon-arrow-left-3: '\eaf2';
$icon-arrow-left-4: '\e915';
$icon-circle-up: '\ea41';
$icon-circle-right: '\ea42';
$icon-circle-down: '\ea43';
$icon-circle-left: '\ea44';
$icon-tab: '\ea45';
$icon-move-up: '\ea46';
$icon-move-down: '\ea47';
$icon-sort-alpha-asc: '\ea48';
$icon-sort-alpha-desc: '\ea49';
$icon-sort-numeric-asc: '\ea4a';
$icon-sort-numberic-desc: '\ea4b';
$icon-sort-amount-asc: '\ea4c';
$icon-sort-amount-desc: '\ea4d';
$icon-command: '\ea4e';
$icon-shift: '\ea4f';
$icon-ctrl: '\ea50';
$icon-opt: '\ea51';
$icon-checkbox-checked: '\ea52';
$icon-checkbox-unchecked: '\ea53';
$icon-radio-checked-1: '\ea54';
$icon-radio-checked-2: '\ea55';
$icon-radio-unchecked: '\ea56';
$icon-crop: '\ea57';
$icon-make-group: '\ea58';
$icon-ungroup: '\ea59';
$icon-scissors: '\ea5a';
$icon-filter-1: '\ea5b';
$icon-filter-2: '\eb02';
$icon-font: '\ea5c';
$icon-ligature-1: '\ea5d';
$icon-ligature-2: '\ea5e';
$icon-text-height: '\ea5f';
$icon-text-width: '\ea60';
$icon-font-size: '\ea61';
$icon-bold: '\ea62';
$icon-underline: '\ea63';
$icon-italic: '\ea64';
$icon-strikethrough: '\ea65';
$icon-omega: '\ea66';
$icon-sigma: '\ea67';
$icon-page-break: '\ea68';
$icon-superscript-1: '\ea69';
$icon-superscript-2: '\ea6b';
$icon-subscript-1: '\ea6a';
$icon-subscript-2: '\ea6c';
$icon-text-color: '\ea6d';
$icon-pagebreak: '\ea6e';
$icon-clear-formatting: '\ea6f';
$icon-table-1: '\ea70';
$icon-table-2: '\ea71';
$icon-insert-template: '\ea72';
$icon-pilcrow: '\ea73';
$icon-ltr: '\ea74';
$icon-rtl: '\ea75';
$icon-section: '\ea76';
$icon-paragraph-left: '\ea77';
$icon-paragraph-center: '\ea78';
$icon-paragraph-right: '\ea79';
$icon-paragraph-justify: '\ea7a';
$icon-indent-increase: '\ea7b';
$icon-indent-decrease: '\ea7c';
$icon-share-1: '\ea7d';
$icon-share-2: '\ea82';
$icon-new-tab: '\ea7e';
$icon-embed-1: '\ea7f';
$icon-embed-2: '\ea80';
$icon-terminal: '\ea81';
$icon-facebook-1: '\ea91';
$icon-facebook-2: '\eb11';
$icon-instagram-1: '\ea92';
$icon-instagram-2: '\eb16';
$icon-whatsapp-1: '\ea93';
$icon-whatsapp-2: '\eb1e';
$icon-twitter-1: '\ea96';
$icon-twitter-2: '\eb14';
$icon-rss-1: '\ea9b';
$icon-rss-2: '\ea9c';
$icon-youtube-1: '\ea9d';
$icon-youtube-2: '\ea9e';
$icon-youtube-3: '\eb15';
$icon-dropbox: '\eaae';
$icon-onedrive: '\eaaf';
$icon-appleinc: '\eabe';
$icon-android: '\eac0';
$icon-skype: '\eac5';
$icon-reddit: '\eac6';
$icon-linkedin-1: '\eac9';
$icon-linkedin-2: '\eb12';
$icon-paypal: '\ead8';
$icon-chrome: '\ead9';
$icon-firefox: '\eada';
$icon-safari: '\eadd';
$icon-opera: '\eade';
$icon-file-pdf: '\eadf';
$icon-file-openoffice: '\eae0';
$icon-file-word: '\eae1';
$icon-file-excel: '\eae2';
$icon-libreoffice: '\eae3';
$icon-git: '\eae7';
$icon-codepen: '\eae8';
$icon-svg: '\eae9';
$icon-icomoon: '\eaea';
$icon-angle-top: '\eaeb';
$icon-angle-right: '\eaec';
$icon-angle-down: '\eaed';
$icon-angle-left: '\eaee';
$icon-triangle-top: '\eaf3';
$icon-triangle-right: '\eaf4';
$icon-triangle-down: '\eaf5';
$icon-triangle-left: '\eaf6';
$icon-checkbox: '\eaf7';
$icon-checked: '\eaf8';
$icon-check: '\eaf9';
$icon-less: '\eafd';
$icon-today: '\eaff';
$icon-clock-3: '\eb00';
$icon-clock-4: '\e918';
$icon-timelapse: '\eb01';
$icon-visibility: '\eb03';
$icon-location-3: '\eb04';
$icon-mail: '\eb05';
$icon-call: '\eb06';
$icon-person: '\eb07';
$icon-mask: '\eb08';
$icon-chat: '\eb09';
$icon-language: '\eb0a';
$icon-computer: '\eb0b';
$icon-verified: '\eb0c';
$icon-brain: '\eb0e';
$icon-star: '\eb10';
$icon-telegram: '\eb13';
$icon-temple: '\eb17';
$icon-tool: '\eb18';
$icon-pencil: '\eb1a';
$icon-record_voice_over: '\eb1b';
$icon-sanitizer: '\eb1c';
$icon-suitcase: '\eb1d';
$icon-close-1: '\eb1f';
$icon-close-2: '\e906';
$icon-link1: '\eb20';
$icon-asterisk: '\eb21';
$icon-notes: '\eb25';
$icon-clean: '\eb26';
$icon-general: '\eb27';
$icon-graduation: '\eb28';
$icon-video: '\eb29';
$icon-thumb: '\eb2a';
$icon-tick: '\e919';
$icon-paperplane: '\e91c';

.icon-home-1:before {
  content: $icon-home-1;
}
.icon-home-2:before {
  content: $icon-home-2;
}
.icon-office:before {
  content: $icon-office;
}
.icon-newspaper:before {
  content: $icon-newspaper;
}
.icon-pencil:before {
  content: $icon-pencil;
}
.icon-quill:before {
  content: $icon-quill;
}
.icon-pen-1:before {
  content: $icon-pen-1;
}
.icon-pen-2:before {
  content: $icon-pen-2;
}
.icon-eyedropper:before {
  content: $icon-eyedropper;
}
.icon-droplet:before {
  content: $icon-droplet;
}
.icon-paint-format:before {
  content: $icon-paint-format;
}
.icon-image:before {
  content: $icon-image;
}
.icon-gallery:before {
  content: $icon-gallery;
}
.icon-camera:before {
  content: $icon-camera;
}
.icon-headphones:before {
  content: $icon-headphones;
}
.icon-music:before {
  content: $icon-music;
}
.icon-bullhorn:before {
  content: $icon-bullhorn;
}
.icon-connection:before {
  content: $icon-connection;
}
.icon-mic:before {
  content: $icon-mic;
}
.icon-book-1:before {
  content: $icon-book-1;
}
.icon-book-2:before {
  content: $icon-book-2;
}
.icon-books:before {
  content: $icon-books;
}
.icon-library:before {
  content: $icon-library;
}
.icon-file-empty:before {
  content: $icon-file-empty;
}
.icon-files-empty:before {
  content: $icon-files-empty;
}
.icon-file-text:before {
  content: $icon-file-text;
}
.icon-file-picture:before {
  content: $icon-file-picture;
}
.icon-file-music:before {
  content: $icon-file-music;
}
.icon-file-play:before {
  content: $icon-file-play;
}
.icon-file-video:before {
  content: $icon-file-video;
}
.icon-file-zip:before {
  content: $icon-file-zip;
}
.icon-copy:before {
  content: $icon-copy;
}
.icon-paste:before {
  content: $icon-paste;
}
.icon-stack:before {
  content: $icon-stack;
}
.icon-folder:before {
  content: $icon-folder;
}
.icon-folder-open:before {
  content: $icon-folder-open;
}
.icon-folder-plus:before {
  content: $icon-folder-plus;
}
.icon-folder-minus:before {
  content: $icon-folder-minus;
}
.icon-folder-download:before {
  content: $icon-folder-download;
}
.icon-folder-upload:before {
  content: $icon-folder-upload;
}
.icon-price-tag:before {
  content: $icon-price-tag;
}
.icon-price-tags:before {
  content: $icon-price-tags;
}
.icon-barcode:before {
  content: $icon-barcode;
}
.icon-qrcode:before {
  content: $icon-qrcode;
}
.icon-ticket:before {
  content: $icon-ticket;
}
.icon-cart:before {
  content: $icon-cart;
}
.icon-coin-dollar:before {
  content: $icon-coin-dollar;
}
.icon-coin-euro:before {
  content: $icon-coin-euro;
}
.icon-coin-pound:before {
  content: $icon-coin-pound;
}
.icon-coin-yen:before {
  content: $icon-coin-yen;
}
.icon-credit-card:before {
  content: $icon-credit-card;
}
.icon-calculator:before {
  content: $icon-calculator;
}
.icon-lifebuoy:before {
  content: $icon-lifebuoy;
}
.icon-phone:before {
  content: $icon-phone;
}
.icon-phone-hang-up:before {
  content: $icon-phone-hang-up;
}
.icon-address-book:before {
  content: $icon-address-book;
}
.icon-envelop:before {
  content: $icon-envelop;
}
.icon-pushpin:before {
  content: $icon-pushpin;
}
.icon-location-1:before {
  content: $icon-location-1;
}
.icon-location-2:before {
  content: $icon-location-2;
}
.icon-compass:before {
  content: $icon-compass;
}
.icon-compass2:before {
  content: $icon-compass2;
}
.icon-map-1:before {
  content: $icon-map-1;
}
.icon-map-2:before {
  content: $icon-map-2;
}
.icon-history:before {
  content: $icon-history;
}
.icon-clock-1:before {
  content: $icon-clock-1;
}
.icon-clock-2:before {
  content: $icon-clock-2;
}
.icon-alarm:before {
  content: $icon-alarm;
}
.icon-bell:before {
  content: $icon-bell;
}
.icon-stop-watch:before {
  content: $icon-stop-watch;
}
.icon-calendar-1:before {
  content: $icon-calendar-1;
}
.icon-calendar-2:before {
  content: $icon-calendar-2;
}
.icon-calendar-3:before {
  content: $icon-calendar-3;
}
.icon-printer:before {
  content: $icon-printer;
}
.icon-key-board:before {
  content: $icon-key-board;
}
.icon-display:before {
  content: $icon-display;
}
.icon-laptop-1:before {
  content: $icon-laptop-1;
}
.icon-laptop-2:before {
  content: $icon-laptop-2;
}
.icon-mobile-1:before {
  content: $icon-mobile-1;
}
.icon-mobile-2:before {
  content: $icon-mobile-2;
}
.icon-tablet:before {
  content: $icon-tablet;
}
.icon-tv:before {
  content: $icon-tv;
}
.icon-drawer-1:before {
  content: $icon-drawer-1;
}
.icon-drawer-2:before {
  content: $icon-drawer-2;
}
.icon-box-add:before {
  content: $icon-box-add;
}
.icon-box-remove:before {
  content: $icon-box-remove;
}
.icon-download-1:before {
  content: $icon-download-1;
}
.icon-download-2:before {
  content: $icon-download-2;
}
.icon-download-3:before {
  content: $icon-download-3;
}
.icon-download-4:before {
  content: $icon-download-4;
}
.icon-upload-1:before {
  content: $icon-upload-1;
}
.icon-upload-2:before {
  content: $icon-upload-2;
}
.icon-upload-3:before {
  content: $icon-upload-3;
}
.icon-floppy-disk:before {
  content: $icon-floppy-disk;
}
.icon-drive:before {
  content: $icon-drive;
}
.icon-database:before {
  content: $icon-database;
}
.icon-undo-1:before {
  content: $icon-undo-1;
}
.icon-undo-2:before {
  content: $icon-undo-2;
}
.icon-redo-1:before {
  content: $icon-redo-1;
}
.icon-redo-2:before {
  content: $icon-redo-2;
}
.icon-reply:before {
  content: $icon-reply;
}
.icon-bubble-1:before {
  content: $icon-bubble-1;
}
.icon-bubble-2:before {
  content: $icon-bubble-2;
}
.icon-bubbles-1:before {
  content: $icon-bubbles-1;
}
.icon-bubbles-2:before {
  content: $icon-bubbles-2;
}
.icon-bubbles-3:before {
  content: $icon-bubbles-3;
}
.icon-bubbles-4:before {
  content: $icon-bubbles-4;
}
.icon-user:before {
  content: $icon-user;
}
.icon-users:before {
  content: $icon-users;
}
.icon-user-plus:before {
  content: $icon-user-plus;
}
.icon-user-minus:before {
  content: $icon-user-minus;
}
.icon-user-check:before {
  content: $icon-user-check;
}
.icon-user-tie:before {
  content: $icon-user-tie;
}
.icon-quotes-left:before {
  content: $icon-quotes-left;
}
.icon-quotes-right:before {
  content: $icon-quotes-right;
}
.icon-hour-glass:before {
  content: $icon-hour-glass;
}
.icon-spinner-1:before {
  content: $icon-spinner-1;
}
.icon-spinner-2:before {
  content: $icon-spinner-2;
}
.icon-spinner-3:before {
  content: $icon-spinner-3;
}
.icon-spinner-4:before {
  content: $icon-spinner-4;
}
.icon-spinner-5:before {
  content: $icon-spinner-5;
}
.icon-spinner-6:before {
  content: $icon-spinner-6;
}
.icon-spinner-7:before {
  content: $icon-spinner-7;
}
.icon-spinner-8:before {
  content: $icon-spinner-8;
}
.icon-spinner-9:before {
  content: $icon-spinner-9;
}
.icon-spinner-10:before {
  content: $icon-spinner-10;
}
.icon-spinner-11:before {
  content: $icon-spinner-11;
}
.icon-spinner-12:before {
  content: $icon-spinner-12;
}
.icon-binoculars:before {
  content: $icon-binoculars;
}
.icon-search-1:before {
  content: $icon-search-1;
}
.icon-search-2:before {
  content: $icon-search-2;
}
.icon-zoom-in:before {
  content: $icon-zoom-in;
}
.icon-zoom-out:before {
  content: $icon-zoom-out;
}
.icon-enlarge:before {
  content: $icon-enlarge;
}
.icon-shrink:before {
  content: $icon-shrink;
}
.icon-enlarge2:before {
  content: $icon-enlarge2;
}
.icon-shrink2:before {
  content: $icon-shrink2;
}
.icon-key-1:before {
  content: $icon-key-1;
}
.icon-key-2:before {
  content: $icon-key-2;
}
.icon-lock:before {
  content: $icon-lock;
}
.icon-unlocked:before {
  content: $icon-unlocked;
}
.icon-wrench:before {
  content: $icon-wrench;
}
.icon-equalizer-1:before {
  content: $icon-equalizer-1;
}
.icon-equalizer-2:before {
  content: $icon-equalizer-2;
}
.icon-cog:before {
  content: $icon-cog;
}
.icon-cogs:before {
  content: $icon-cogs;
}
.icon-hammer-1:before {
  content: $icon-hammer-1;
}
.icon-hammer-2:before {
  content: $icon-hammer-2;
}
.icon-magic-wand:before {
  content: $icon-magic-wand;
}
.icon-aid-kit:before {
  content: $icon-aid-kit;
}
.icon-bug:before {
  content: $icon-bug;
}
.icon-pie-chart:before {
  content: $icon-pie-chart;
}
.icon-stats-dots:before {
  content: $icon-stats-dots;
}
.icon-stats-bars-1:before {
  content: $icon-stats-bars-1;
}
.icon-stats-bars-2:before {
  content: $icon-stats-bars-2;
}
.icon-trophy:before {
  content: $icon-trophy;
}
.icon-gift:before {
  content: $icon-gift;
}
.icon-glass-1:before {
  content: $icon-glass-1;
}
.icon-glass-2:before {
  content: $icon-glass-2;
}
.icon-mug:before {
  content: $icon-mug;
}
.icon-spoon-knife:before {
  content: $icon-spoon-knife;
}
.icon-leaf:before {
  content: $icon-leaf;
}
.icon-rocket-1:before {
  content: $icon-rocket-1;
}
.icon-rocket-2:before {
  content: $icon-rocket-2;
}
.icon-meter-1:before {
  content: $icon-meter-1;
}
.icon-meter-2:before {
  content: $icon-meter-2;
}
.icon-fire:before {
  content: $icon-fire;
}
.icon-lab:before {
  content: $icon-lab;
}
.icon-magnet:before {
  content: $icon-magnet;
}
.icon-bin-1:before {
  content: $icon-bin-1;
}
.icon-bin-2:before {
  content: $icon-bin-2;
}
.icon-briefcase:before {
  content: $icon-briefcase;
}
.icon-airplane:before {
  content: $icon-airplane;
}
.icon-truck:before {
  content: $icon-truck;
}
.icon-road:before {
  content: $icon-road;
}
.icon-accessibility:before {
  content: $icon-accessibility;
}
.icon-target:before {
  content: $icon-target;
}
.icon-shield:before {
  content: $icon-shield;
}
.icon-power:before {
  content: $icon-power;
}
.icon-switch:before {
  content: $icon-switch;
}
.icon-power-cord:before {
  content: $icon-power-cord;
}
.icon-clipboard:before {
  content: $icon-clipboard;
}
.icon-list-numbered:before {
  content: $icon-list-numbered;
}
.icon-list-1:before {
  content: $icon-list-1;
}
.icon-list-2:before {
  content: $icon-list-2;
}
.icon-tree:before {
  content: $icon-tree;
}
.icon-menu-1:before {
  content: $icon-menu-1;
}
.icon-menu-2:before {
  content: $icon-menu-2;
}
.icon-menu-3:before {
  content: $icon-menu-3;
}
.icon-menu-4:before {
  content: $icon-menu-4;
}
.icon-menu-5:before {
  content: $icon-menu-5;
}
.icon-cloud:before {
  content: $icon-cloud;
}
.icon-cloud-download:before {
  content: $icon-cloud-download;
}
.icon-cloud-upload:before {
  content: $icon-cloud-upload;
}
.icon-cloud-check:before {
  content: $icon-cloud-check;
}
.icon-sphere:before {
  content: $icon-sphere;
}
.icon-earth:before {
  content: $icon-earth;
}
.icon-link:before {
  content: $icon-link;
}
.icon-flag:before {
  content: $icon-flag;
}
.icon-attachment:before {
  content: $icon-attachment;
}
.icon-eye:before {
  content: $icon-eye;
}
.icon-eye-plus:before {
  content: $icon-eye-plus;
}
.icon-eye-minus:before {
  content: $icon-eye-minus;
}
.icon-eye-blocked:before {
  content: $icon-eye-blocked;
}
.icon-bookmark:before {
  content: $icon-bookmark;
}
.icon-bookmarks:before {
  content: $icon-bookmarks;
}
.icon-sun:before {
  content: $icon-sun;
}
.icon-contrast:before {
  content: $icon-contrast;
}
.icon-brightness-contrast:before {
  content: $icon-brightness-contrast;
}
.icon-star-empty:before {
  content: $icon-star-empty;
}
.icon-star-half:before {
  content: $icon-star-half;
}
.icon-star-full:before {
  content: $icon-star-full;
}
.icon-heart:before {
  content: $icon-heart
}
.icon-heart-broken:before {
  content: $icon-heart-broken;
}
.icon-man:before {
  content: $icon-man;
}
.icon-woman:before {
  content: $icon-woman;
}
.icon-man-woman:before {
  content: $icon-man-woman;
}
.icon-point-up:before {
  content: $icon-point-up;
}
.icon-point-right:before {
  content: $icon-point-right;
}
.icon-point-down:before {
  content: $icon-point-down;
}
.icon-point-left:before {
  content: $icon-point-left;
}
.icon-warning:before {
  content: $icon-warning;
}
.icon-notification:before {
  content: $icon-notification;
}
.icon-question:before {
  content: $icon-question;
}
.icon-plus-1:before {
  content: $icon-plus-1;
}
.icon-plus-2:before {
  content: $icon-plus-2;
}
.icon-plus-3:before {
  content: $icon-plus-3;
}
.icon-minus:before {
  content: $icon-minus;
}
.icon-info:before {
  content: $icon-info;
}
.icon-cancel-circle:before {
  content: $icon-cancel-circle;
}
.icon-blocked:before {
  content: $icon-blocked;
}
.icon-cross-1:before {
  content: $icon-cross-1;
}
.icon-cross-2:before {
  content: $icon-cross-2;
}
.icon-checkmark-1:before {
  content: $icon-checkmark-1;
}
.icon-checkmark-2:before {
  content: $icon-checkmark-2;
}
.icon-spell-check:before {
  content: $icon-spell-check;
}
.icon-enter:before {
  content: $icon-enter;
}
.icon-exit:before {
  content: $icon-exit;
}
.icon-play-1:before {
  content: $icon-play-1;
}
.icon-play-2:before {
  content: $icon-play-2;
}
.icon-play-3:before {
  content: $icon-play-3;
}
.icon-pause-1:before {
  content: $icon-pause-1;
}
.icon-pause-2:before {
  content: $icon-pause-2;
}
.icon-stop-1:before {
  content: $icon-stop-1;
}
.icon-previous-1:before {
  content: $icon-previous-1;
}
.icon-previous-2:before {
  content: $icon-previous-2;
}
.icon-next-1:before {
  content: $icon-next-1;
}
.icon-next-2:before {
  content: $icon-next-2;
}
.icon-backward-1:before {
  content: $icon-backward-1;
}
.icon-backward-2:before {
  content: $icon-backward-2;
}
.icon-forward-1:before {
  content: $icon-forward-1;
}
.icon-forward-2:before {
  content: $icon-forward-2;
}
.icon-forward-3:before {
  content: $icon-forward-3;
}
.icon-stop-2:before {
  content: $icon-stop-2;
}
.icon-first:before {
  content: $icon-first;
}
.icon-last:before {
  content: $icon-last;
}
.icon-eject:before {
  content: $icon-eject;
}
.icon-volume-high:before {
  content: $icon-volume-high;
}
.icon-volume-medium:before {
  content: $icon-volume-medium;
}
.icon-volume-low:before {
  content: $icon-volume-low;
}
.icon-volume-mute-1:before {
  content: $icon-volume-mute-1;
}
.icon-volume-mute-2:before {
  content: $icon-volume-mute-2;
}
.icon-volume-increase:before {
  content: $icon-volume-increase;
}
.icon-volume-decrease:before {
  content: $icon-volume-decrease;
}
.icon-loop-1:before {
  content: $icon-loop-1;
}
.icon-loop-2:before {
  content: $icon-loop-2;
}
.icon-infinite:before {
  content: $icon-infinite;
}
.icon-shuffle:before {
  content: $icon-shuffle;
}
.icon-arrow-up-left-1:before {
  content: $icon-arrow-up-left-1;
}
.icon-arrow-up-left-2:before {
  content: $icon-arrow-up-left-2;
}
.icon-arrow-up-1:before {
  content: $icon-arrow-up-1;
}
.icon-arrow-up-2:before {
  content: $icon-arrow-up-2;
}
.icon-arrow-up-3:before {
  content: $icon-arrow-up-3;
}
.icon-arrow-up-4:before {
  content: $icon-arrow-up-4;
}
.icon-arrow-up-right-1:before {
  content: $icon-arrow-up-right-1;
}
.icon-arrow-up-right-2:before {
  content: $icon-arrow-up-right-2;
}
.icon-arrow-right-1:before {
  content: $icon-arrow-right-1;
}
.icon-arrow-right-2:before {
  content: $icon-arrow-right-2;
}
.icon-arrow-right-3:before {
  content: $icon-arrow-right-3;
}
.icon-arrow-right-4:before {
  content: $icon-arrow-right-4;
}
.icon-arrow-down-right-1:before {
  content: $icon-arrow-down-right-1;
}
.icon-arrow-down-right-2:before {
  content: $icon-arrow-down-right-2;
}
.icon-arrow-down-1:before {
  content: $icon-arrow-down-1;
}
.icon-arrow-down-2:before {
  content: $icon-arrow-down-2;
}
.icon-arrow-down-3:before {
  content: $icon-arrow-down-3;
}
.icon-arrow-down-4:before {
  content: $icon-arrow-down-4;
}
.icon-arrow-down-left-1:before {
  content: $icon-arrow-down-left-1;
}
.icon-arrow-down-left-2:before {
  content: $icon-arrow-down-left-2;
}
.icon-arrow-left-1:before {
  content: $icon-arrow-left-1;
}
.icon-arrow-left-2:before {
  content: $icon-arrow-left-2;
}
.icon-arrow-left-3:before {
  content: $icon-arrow-left-3;
}
.icon-arrow-left-4:before {
  content: $icon-arrow-left-4;
}
.icon-circle-up:before {
  content: $icon-circle-up;
}
.icon-circle-right:before {
  content: $icon-circle-right;
}
.icon-circle-down:before {
  content: $icon-circle-down;
}
.icon-circle-left:before {
  content: $icon-circle-left;
}
.icon-tab:before {
  content: $icon-tab;
}
.icon-move-up:before {
  content: $icon-move-up;
}
.icon-move-down:before {
  content: $icon-move-down;
}
.icon-sort-alpha-asc:before {
  content: $icon-sort-alpha-asc;
}
.icon-sort-alpha-desc:before {
  content: $icon-sort-alpha-desc;
}
.icon-sort-numeric-asc:before {
  content: $icon-sort-numeric-asc;
}
.icon-sort-numberic-desc:before {
  content: $icon-sort-numberic-desc;
}
.icon-sort-amount-asc:before {
  content: $icon-sort-amount-asc;
}
.icon-sort-amount-desc:before {
  content: $icon-sort-amount-desc;
}
.icon-command:before {
  content: $icon-command;
}
.icon-shift:before {
  content: $icon-shift;
}
.icon-ctrl:before {
  content: $icon-ctrl;
}
.icon-opt:before {
  content: $icon-opt;
}
.icon-checkbox-checked:before {
  content: $icon-checkbox-checked;
}
.icon-checkbox-unchecked:before {
  content: $icon-checkbox-unchecked;
}
.icon-radio-checked-1:before {
  content: $icon-radio-checked-1;
}
.icon-radio-checked-2:before {
  content: $icon-radio-checked-2;
}
.icon-radio-unchecked:before {
  content: $icon-radio-unchecked;
}
.icon-crop:before {
  content: $icon-crop;
}
.icon-make-group:before {
  content: $icon-make-group;
}
.icon-ungroup:before {
  content: $icon-ungroup;
}
.icon-scissors:before {
  content: $icon-scissors;
}
.icon-filter-1:before {
  content: $icon-filter-1;
}
.icon-filter-2:before {
  content: $icon-filter-2;
}
.icon-font:before {
  content: $icon-font;
}
.icon-ligature-1:before {
  content: $icon-ligature-1;
}
.icon-ligature-2:before {
  content: $icon-ligature-2;
}
.icon-text-height:before {
  content: $icon-text-height;
}
.icon-text-width:before {
  content: $icon-text-width;
}
.icon-font-size:before {
  content: $icon-font-size;
}
.icon-bold:before {
  content: $icon-bold;
}
.icon-underline:before {
  content: $icon-underline;
}
.icon-italic:before {
  content: $icon-italic;
}
.icon-strikethrough:before {
  content: $icon-strikethrough;
}
.icon-omega:before {
  content: $icon-omega;
}
.icon-sigma:before {
  content: $icon-sigma;
}
.icon-page-break:before {
  content: $icon-page-break;
}
.icon-superscript-1:before {
  content: $icon-superscript-1;
}
.icon-superscript-2:before {
  content: $icon-superscript-2;
}
.icon-subscript-1:before {
  content: $icon-subscript-1;
}
.icon-subscript-2:before {
  content: $icon-subscript-2;
}
.icon-text-color:before {
  content: $icon-text-color;
}
.icon-pagebreak:before {
  content: $icon-pagebreak;
}
.icon-clear-formatting:before {
  content: $icon-clear-formatting;
}
.icon-table-1:before {
  content: $icon-table-1;
}
.icon-table-2:before {
  content: $icon-table-2;
}
.icon-insert-template:before {
  content: $icon-insert-template;
}
.icon-pilcrow:before {
  content: $icon-pilcrow;
}
.icon-ltr:before {
  content: $icon-ltr;
}
.icon-rtl:before {
  content: $icon-rtl;
}
.icon-section:before {
  content: $icon-section;
}
.icon-paragraph-left:before {
  content: $icon-paragraph-left;
}
.icon-paragraph-center:before {
  content: $icon-paragraph-center;
}
.icon-paragraph-right:before {
  content: $icon-paragraph-right;
}
.icon-paragraph-justify:before {
  content: $icon-paragraph-justify;
}
.icon-indent-increase:before {
  content: $icon-indent-increase;
}
.icon-indent-decrease:before {
  content: $icon-indent-decrease;
}
.icon-share-1:before {
  content: $icon-share-1;
}
.icon-share-2:before {
  content: $icon-share-2;
}
.icon-new-tab:before {
  content: $icon-new-tab
}
.icon-embed-1:before {
  content: $icon-embed-1;
}
.icon-embed-2:before {
  content: $icon-embed-2;
}
.icon-terminal:before {
  content: $icon-terminal;
}
.icon-facebook-1:before {
  content: $icon-facebook-1;
}
.icon-facebook-2:before {
  content: $icon-facebook-2;
}
.icon-instagram-1:before {
  content: $icon-instagram-1;
}
.icon-instagram-2:before {
  content: $icon-instagram-2;
}
.icon-whatsapp-1:before {
  content: $icon-whatsapp-1;
}
.icon-whatsapp-2:before {
  content: $icon-whatsapp-2;
}
.icon-twitter-1:before {
  content: $icon-twitter-1;
}
.icon-twitter-2:before {
  content: $icon-twitter-2;
}
.icon-rss-1:before {
  content: $icon-rss-1;
}
.icon-rss-2:before {
  content: $icon-rss-2;
}
.icon-youtube-1:before {
  content: $icon-youtube-1;
}
.icon-youtube-2:before {
  content: $icon-youtube-2;
}
.icon-youtube-3:before {
  content: $icon-youtube-3;
}
.icon-dropbox:before {
  content: $icon-dropbox;
}
.icon-onedrive:before {
  content: $icon-onedrive;
}
.icon-appleinc:before {
  content: $icon-appleinc;
}
.icon-android:before {
  content: $icon-android;
}
.icon-skype:before {
  content: $icon-skype;
}
.icon-reddit:before {
  content: $icon-reddit;
}
.icon-linkedin-1:before {
  content: $icon-linkedin-1;
}
.icon-linkedin-2:before {
  content: $icon-linkedin-2;
}
.icon-paypal:before {
  content: $icon-paypal;
}
.icon-chrome:before {
  content: $icon-chrome;
}
.icon-firefox:before {
  content: $icon-firefox;
}
.icon-safari:before {
  content: $icon-safari;
}
.icon-opera:before {
  content: $icon-opera;
}
.icon-file-pdf:before {
  content: $icon-file-pdf;
}
.icon-file-openoffice:before {
  content: $icon-file-openoffice;
}
.icon-file-word:before {
  content: $icon-file-word;
}
.icon-file-excel:before {
  content: $icon-file-excel;
}
.icon-libreoffice:before {
  content: $icon-libreoffice;
}
.icon-git:before {
  content: $icon-git;
}
.icon-codepen:before {
  content: $icon-codepen;
}
.icon-svg:before {
  content: $icon-svg;
}
.icon-icomoon:before {
  content: $icon-icomoon;
}
.icon-angle-top:before {
  content: $icon-angle-top;
}
.icon-angle-right:before {
  content: $icon-angle-right;
}
.icon-angle-down:before {
  content: $icon-angle-down;
}
.icon-angle-left:before {
  content: $icon-angle-left;
}
.icon-triangle-top:before {
  content: $icon-triangle-top;
}
.icon-triangle-right:before {
  content: $icon-triangle-right;
}
.icon-triangle-down:before {
  content: $icon-triangle-down;
}
.icon-triangle-left:before {
  content: $icon-triangle-left;
}
.icon-checkbox:before {
  content: $icon-checkbox;
}
.icon-checked:before {
  content: $icon-checked;
}
.icon-check:before {
  content: $icon-check;
}
.icon-less:before {
  content: $icon-less;
}
.icon-today:before {
  content: $icon-today;
}
.icon-clock-3:before {
  content: $icon-clock-3;
}
.icon-clock-4:before {
  content: $icon-clock-4;
}
.icon-timelapse:before {
  content: $icon-timelapse;
}
.icon-visibility:before {
  content: $icon-visibility;
}
.icon-location-3:before {
  content: $icon-location-3;
}
.icon-mail:before {
  content: $icon-mail;
}
.icon-call:before {
  content: $icon-call;
}
.icon-person:before {
  content: $icon-person;
}
.icon-mask:before {
  content: $icon-mask;
}
.icon-chat:before {
  content: $icon-chat;
}
.icon-language:before {
  content: $icon-language;
}
.icon-computer:before {
  content: $icon-computer;
}
.icon-verified:before {
  content: $icon-verified;
}
.icon-brain:before {
  content: $icon-brain;
}
.icon-star:before {
  content: $icon-star;
}
.icon-telegram:before {
  content: $icon-telegram;
}
.icon-temple:before {
  content: $icon-temple;
}
.icon-tool:before {
  content: $icon-tool;
}
.icon-pencil:before {
  content: $icon-pencil;
}
.icon-record-voice-over:before {
  content: $icon-record-voice-over;
}
.icon-sanitizer:before {
  content: $icon-sanitizer;
}
.icon-suitcase:before {
  content: $icon-suitcase;
}
.icon-close-1:before {
  content: $icon-close-1;
}
.icon-close-2:before {
  content: $icon-close-2;
}
.icon-link:before {
  content: $icon-link;
}
.icon-asterisk:before {
  content: $icon-asterisk;
}
.icon-notes:before {
  content: $icon-notes;
}
.icon-clean:before {
  content: $icon-clean;
}
.icon-general:before {
  content: $icon-general;
}
.icon-graduation:before {
  content: $icon-graduation;
}
.icon-video:before {
  content: $icon-video;
}
.icon-thumb:before {
  content: $icon-thumb;
}
.icon-tick:before {
  content: $icon-tick;
}
.icon-paperplane:before {
  content: $icon-paperplane;
}
