// Breakpoints
@mixin mobile-small {
  @media (min-width: #{$breakpoint-mobile-small}) {
    @content;
  }
}

@mixin mobile-medium {
  @media (min-width: #{$breakpoint-mobile-medium}) {
    @content;
  }
}

// REM Conversor
  /* Use:
    .element {
      font-size: rem(20);
    }
  */
  @function rem($size) {
    @return calc(($size / $font-base) * 1rem) ;
  }
