// .form__error
.form__error {
  @extend %text-caption;
  animation: shake 0.15s linear 2 forwards;
  color: $color-negative;
  margin-top: $stack-xs;
}

.form {
  &__error {
    text-align: initial
  }

  &__input {
    display: inline-block;
    width: 100%;
  }
}