@import './../base/variables';
@import './../base/mixins';

.header {
  align-items: center;
  background: linear-gradient(90deg, #{darken($color-secondary, 12%)} 10%, #{$color-secondary} 90%);
  box-shadow: 0px 6px 12px rgba($color-secondary-accent, 0);
  display: flex;
  justify-content: space-between;
  padding: $wrapper-padding-sm $wrapper-padding-sm $wrapper-padding-sm 5px;
  transition: border-radius $transition-header-animation ease-out, box-shadow $transition-header-animation ease-out;
  width: 100%;

  @include mobile-small {
    padding:$wrapper-padding-sm $wrapper-padding-sm $wrapper-padding-sm $wrapper-padding-sm;
  }
  
  &__navigation {
    &[class*="button"] {
      border-color: transparent;
      color: $color-white;
    }
  }

  &__platform {
    border-radius: rem(50);
    height: rem(50);
    width: rem(50);

    @include mobile-small {
      border-radius: rem(60);
      height: rem(60);
      width: rem(60);
    }
  }

  &__platform--cheerfy {
    background: $color-cheerfy url('../../../assets/images/logo-squared-stuart.svg') no-repeat center 45%/ #{rem(32)};
    border-color: $color-cheerfy;

    @include mobile-small {
      background-size: rem(38);
    }
  }

  &__platform--flipdish {
    background: $color-flipdish url('../../../assets/images/logo-squared-flipdish.svg') no-repeat center / #{rem(38)};
    border-color: $color-flipdish;

    @include mobile-small {
      background-size: rem(42);
    }
  }

  &__platform--glovo {
    background: $color-glovo url('../../../assets/images/logo-squared-glovo.svg') no-repeat center 45%/ #{rem(40)};
    border-color: $color-glovo;

    @include mobile-small {
      background-size: rem(46);
    }
  }

  &__platform--just-eat {
    background: $color-just-eat url('../../../assets/images/logo-squared-just-eat.svg') no-repeat center 47%/ #{rem(30)};
    border-color: $color-just-eat;

    @include mobile-small {
      background-size: rem(34);
    }
  }

  &__platform--uber-eats {
    background: $color-uber-eats url('../../../assets/images/logo-squared-uber-eats.svg') no-repeat center / #{rem(30)};
    border-color: $color-uber-eats;

    @include mobile-small {
      background-size: rem(36);
    }
  }

  &--animation {
    border-radius: 0 0 rem($radius-intro) rem($radius-intro);
    box-shadow: 0px 6px 12px rgba($color-secondary-accent, 0.5);
  }
}