html {
  -ms-text-size-adjust: 100%; //sass-lint:disable-line no-vendor-prefixes
  -webkit-text-size-adjust: 100%; //sass-lint:disable-line no-vendor-prefixes
  -webkit-font-smoothing: antialiased; //sass-lint:disable-line no-vendor-prefixes
  -moz-osx-font-smoothing: grayscale; //sass-lint:disable-line no-vendor-prefixes
  text-rendering: optimizeLegibility;
  font-family: $font-text;
  font-size: 100%;
  color: $color-text;
}

%text-h1,
.text-h1 {
  font: $font-medium #{rem(24) }/ #{rem(32)} $font-title;
  letter-spacing: 0.1px;

  @include mobile-small {
    font-size: rem(28);
    line-height: rem(38);
  }
}
 
%text-h2,
.text-h2 {
  font: $font-semibold #{rem(18)} / #{rem(26)} $font-subtitle;
  
  @include mobile-small {
    font-size: rem(20);
    line-height: rem(28);
  }
}

%text-h3,
.text-h3 {
  font: $font-semibold #{rem(17)} / #{rem(24)} $font-subtitle;
  letter-spacing: -0.05px;
  
  @include mobile-small {
    font-size: rem(19);
    line-height: rem(26);
  }
}

%text-p,
.text-p {
  font: $font-regular #{rem(15)} / #{rem(22)} $font-text;
  letter-spacing: -0.5px;

  @include mobile-small {
    font-size: rem(17);
    line-height: rem(24);
  }
}

%text-input,
.text-input {
  font: $font-regular #{rem(18)} / #{rem(26)} $font-text;
  letter-spacing: -0.35px;
}

%text-button,
.text-button {
  font: $font-semibold #{rem(15)} / #{rem(16)} $font-button;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

%text-caption,
.text-caption {
  font: $font-medium #{rem(15)} / #{rem(20)} $font-text;
  letter-spacing: -0.55px;
}

button,
input,
select,
textarea,
fieldset,
legend,
label,
select {
  font-family: $font-text;
  font-weight: $font-regular;
}
