// Brand color
$color-primary: #ff1070;
$color-primary-accent: darken($color-primary, 20%);
$color-secondary: #840b55;
$color-secondary-accent: darken($color-secondary, 20%);
$color-tertiary: #ffad00;
$color-tertiary-accent: darken($color-tertiary, 20%);

// Complementary color
$color-blue: #009de2;
$color-green: #00bb7e;
$color-pink: #f57eb6;
$color-purple: #a7166f;
$color-red: #e63888;
$color-yellow: #ffad00;

// Text color
$color-black: #212121;
$color-grey-dark: #566374;
$color-grey: #bec9d9;
$color-grey-light: #e2edf9;
$color-white: #fff;

// Semantic color
$color-positive: $color-green;
$color-warning: $color-yellow;
$color-negative: #ff0000;
$color-neutral: $color-grey;
$color-info: $color-blue;

// UI color
$color-ui-0: #f7fbfe;
$color-ui-25: #f1f8ff;
$color-ui-50: #eaf3fd; 
$color-ui-100: #dce7f4;
$color-ui-200: #d0dff1;
$color-ui-300: #bccde2;
$color-ui-400: #a2b4cb;
$color-ui-500: #7d93ae;
$color-ui-600: #536e8e;
$color-ui-700: #42556c;
$color-ui-800: #27384c;
$color-ui-900: #192431;

// Util color
$color-bg: $color-white;
$color-backdrop: $color-black;
$color-detail: $color-ui-600;
$color-title: $color-black;
$color-subtitle: $color-purple;
$color-text: $color-grey-dark;
$color-text--invert: $color-white;
$color-text--disabled: $color-neutral;
$color-input: $color-ui-50;
$color-input-accent: $color-ui-500;
$color-success: $color-positive;
$color-error: $color-negative;
$color-disabled: $color-grey;
$color-placeholder: $color-ui-400;

// Platforms
$color-glovo: #ffc244;
$color-uber-eats: #262626;
$color-just-eat: #fb6100;
$color-flipdish: #1813a5;
$color-cheerfy: #00aaf1;

// Breakpoints
$breakpoint-mobile-small: 375px;
$breakpoint-mobile-medium: 414px;

// Layout
$backdrop-opacity: 0.70;
$header-height: 74;   
$checkbox-height: 26;   
// <================= TODOOOOO
$intro-background-gap: 60;
$wrapper-padding-sm: 20px;
$wrapper-padding-md: 30px;
$wrapper-padding-lg: 40px;

// Z-index
$index-modal: 999 !default;
$index-form: 10 !default;
$index-header: 10 !default;
$index-content: 1 !default;
$index-underground: -1 !default;

// Spacing
$inline-xs: 2px !default;
$inline-sm: 4px !default;
$inline-md: 8px !default;
$inline-lg: 16px !default;
$inline-xl: 32px !default;
$inline-xxl: 64px !default;

$stack-xs: 4px !default;
$stack-sm: 8px !default;
$stack-md: 16px !default;
$stack-lg: 28px !default;
$stack-xl: 32px !default;
$stack-xxl: 64px !default;
$stack-xxxl: 96px !default;

// $inset-sm: 8px 6px !default;
$inset-md: 16px 14px !default;
// $inset-lg: 24px 20px !default;
// $inset-xl: 28px 24px !default;
$inset-xxl: 32px 28px !default;

// Border
$border: 1px !default;
$border-button: 2px !default;
$border-icon: 2px !default;
$border-input: 2px !default;
$border-line: 2px !default;
$border-outline: 3px !default;

// Border radius
$radius-button: 10 !default;
$radius-card: 16 !default;
$radius-intro: 40 !default;
$radius-input: 12 !default;
$radius-modal: 20 !default;

// Font
$font-base: 16;
$font-title: 'Poppins Medium', sans-serif;
$font-subtitle: 'Poppins Semibold', sans-serif;
$font-text: 'Work-Sans', sans-serif;
$font-button: $font-subtitle;

$font-light: 300 !default;
$font-regular: 400 !default;
$font-medium: 500 !default;
$font-semibold: 600 !default;

// Transition
$transition-delay: 0ms !default;
$transition-hover: 150ms !default;
$transition-header-animation: 500ms !default;
