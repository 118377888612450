.intro {
  background: $color-secondary;
  background: linear-gradient(90deg, #{darken($color-secondary, 12%)} 10%, #{$color-secondary} 90%);
  border-radius: 0 0 rem($radius-intro) rem($radius-intro);
  color: $color-white;
  padding-bottom: calc(#{rem($intro-background-gap)} / 1.10);
  padding-top: rem(28);
  position: relative;

  @include mobile-small {
    padding-bottom: rem($intro-background-gap);
  }
}