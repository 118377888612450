@keyframes shake {
  0% {
    transform: translateX(#{rem(4)});
  }
  50% {
    transform: translateX(-#{rem(4)});
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes translateRight {
  0% {
    transform: translateX(0);
  }  
  50% {
    transform: translateX(#{rem(6)});
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes spinLoop {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: translateY(#{rem(-2)}) rotate(0deg);
  }
  to {
    transform: translateY(#{rem(-2)}) rotate(360deg);
  }
}
 