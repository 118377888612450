// Margins
$stack: $stack-xs $stack-sm $stack-md $stack-lg $stack-xl $stack-xxl $stack-xxxl;
$inline: $inline-xs $inline-sm $inline-md $inline-lg $inline-xl $inline-xxl;
$names: 'xs' 's' 'm' 'l' 'xl' 'xxl' 'xxxl';

@each $size in $stack {
  $i: index($stack, $size);

  .mt-#{nth($names, $i)} {
    margin-top: #{$size};
  }
  
  .mb-#{nth($names, $i)} {
    margin-bottom: #{$size};
  }
}

@each $size in $inline {
  $i: index($inline, $size);

  .mr-#{nth($names, $i)} {
    margin-right: #{$size};
  }

  .ml-#{nth($names, $i)} {
    margin-left: #{$size};
  }
}
